.subnav
	ul
		font-size: 0
		letter-spacing: 0
		margin: 0 auto
		padding: 0
		word-spacing: 0
		text-align: center
		white-space: nowrap
		overflow: auto
		position: relative
		li
			letter-spacing: normal
			word-spacing: normal
			display: inline-block
			vertical-align: middle
			& + li
				margin-left: r(12px)
			a
				@extend .body-3
				font-weight: 700
				height: r(48px)
				display: flex
				+flex-center
				+trans-all
				padding: 0 r(16px)
				border-radius: r(8px)
			&.active
				a
					+bg(primary-600)
					+text(white)
			+on-hover
				a
					+bg(primary-600)
					+text(white)
