$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
@each $color, $value in $button
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, #333, #fff)
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, #333, #fff)

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(8px) r(16px)
	display: inline-flex
	+trans-all
	+font(700,16px,24px)
	+flex-center
	text-transform: uppercase
	gap: r(8px)
	border-radius: r(8px)
	&-pill
		border-radius: 999px
	&-lg
		padding: 0 r(16px) 0 r(24px)
		gap: r(20px)
		height: r(44px)

.swiper-btn
	display: inline-flex
	+flex-center
	+box(60px)
	+text(primary-600)
	font-size: r(32px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	border: 1px solid color(primary-600)
	border-radius: r(8px)
	+remove(lg,max)
	+on-hover
		+bg(primary-600)
		+text(white)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none
	&.no-box
		width: auto
		height: auto
		background: transparent
		+text(primary-600)
		+on-hover
			+text(primary-600)

.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(30px)
			&.swiper-prev
				right: 100%
			&.swiper-next
				left: 100%
			+mq-max(2xl)
				margin: 0 r(10px)

		&.is-no-gap
			.swiper-btn
				margin: 0
				&.swiper-prev
					right: auto
					left: 0
				&.swiper-next
					left: auto
					right: 0
	&.is-left
		display: flex
		gap: r(12px)