.event-detail-info
	ul
		+layout-col(24px)
		li
			+layout-col(12px)
			@extend .heading-5
			strong
				display: flex
				align-items: center
				justify-content: space-between
				+text(primary-600)
				gap: r(20px)
				&::after
					content: ''
					+flex-width(60%)
					height: 1px
					+bg(gray-200)
			span
				+text(gray-600)

