.news-item
	+bg(white)
	border-radius: r(12px)
	overflow: hidden
	@extend .body-3
	&-meta
		display: flex
		align-items: center
		gap: r(12px)
		+text(gray-300)
		span
			display: inline-block
			width: 2px
			height: 2px
			+bg(gray-400)
	&-img
		overflow: hidden
	&-cate
		font-weight: 700
	&-title
		font-weight: 700
		+trans-all
		+line(3)
		+mq-max(md)
			+fz-lh(20px,28px)
	&-big
		.news-item-caption
			padding: r(40px)
			+mq-max(md)
				padding: r(24px)
		.news-item-title
			@extend .heading-5
	&-horizontal
		display: flex
		.news-item-img
			+flex-width(136 / 416 * 100%)
			+mq-max(md)
				+flex-width(33.33333%)
		.news-item-meta 
			@extend .body-5
		.news-item-title
			+mq-min(md)
				+line(2)
	&-brief
		+line(6)
	+on-hover
		.news-item-title
			+text(primary-600)

.news-list
	.news-item
		&-small
			+mq-max(md)
				display: flex
				.news-item-img
					+flex-width(33.33333%)