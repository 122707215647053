.footer-top
	+bg-gra(linear-2)
	+text(white)
	h3
		@extend .body-4
		font-weight: 400
		margin-block: r(8px)
		text-transform: uppercase

.footer-logo
	a
		display: block
		+flex-width(r(80px))

.footer-bottom
	h3
		font-weight: 700
		@extend .body-3
		margin-bottom: r(20px)
		text-transform: uppercase
	.footer-link
		ul
			+layout-col(8px)
			li
				a
					@extend .body-3
					@extend .link-hover
	.footer-col
		+mq-min(lg)
			&:not(:last-child)
				.footer-item
					border-right: 1px solid color(gray-100)
	.footer-social
		ul
			display: flex
			gap: r(8px)
			li
				a
					+box(48px)
					display: flex
					+flex-center
					+bg(white)
					border-radius: r(8px)
					+text(gray-500)
					+fz(24px)

.subscribefrm
	position: relative
	input
		width: 100%
		height: r(40px)
		padding: 0 r(48px) 0 r(20px)
		border: 0
		+bg(white)
		+fz-lh(16px,24px)
		+placeholder
			+text(gray-400)
	button
		+reset-button
		+text(primary-600)
		+fz(20px)
		position: absolute
		top: 0
		right: 0
		height: 100%
		padding-right: r(20px)

.terms
	ul
		+mq-min(sm)
			+layout-row(16px)
			+content-margins
				padding-left: r(16px)
				border-left: 1px solid color(gray-500)
