.event-item
	+mq-min(md)
		+row(32px,32px)
	@extend .body-3
	position: relative
	&-date
		+mq-max(lg)
			position: absolute
			top: r(12px)
			left: r(12px)
			z-index: 1
		+mq-min(lg)
			+flex-width(2 / 12 * 100%)
		.date-box
			text-align: center
			+text(primary-600)
			.day
				@extend .heading-1
				font-weight: 700
				padding: r(40px) 0 r(20px)
				border-bottom: 1px solid color(gray-100)
				+mq-max(lg)
					padding: 0
					+fz-lh(28px,32px)
					padding-bottom: r(12px)
			.month
				padding-top: r(20px)
				+mq-max(lg)
					padding-top: r(12px)
			+mq-max(lg)
				padding: r(12px)
				+bg(white)
	&-img
		+mq-min(md)
			+flex-width(4 / 12 * 100%)
		figure
			border-radius: r(12px)
			overflow: hidden
	&-caption
		+mq-min(md)
			+flex-width(8 / 12 * 100%)
		+mq-min(lg)
			+flex-width(6 / 12 * 100%)
		&-inner
			+bg(white)
			border-radius: r(12px)
			padding: r(24px)
			+mq-min(md)
				padding: r(60px) r(32px) r(60px) r(64px)
				margin-left: r(-32px)
				height: 100%
	&-title
		@extend .heading-5
		font-weight: 700
		+trans-all
		+line(2)
	&-meta
		+text(gray-300)
	&-brief
		+line(6)
	+on-hover
		.event-item-title
			+text(primary-600)