.about-section-3
	background-size: cover
	background-position: center
	background-repeat: no-repeat
	padding: r(40px) 0
	+mq-min(lg)
		background-size: contain
		min-height: r(800px)
		display: flex
		align-items: center
		> *
			width: 100%
	.content
		padding: r(40px) r(32px)
		border-radius: r(16px)
		background: linear-gradient(45deg, rgba(234, 234, 234, 0.35) 0%, rgba(255, 255, 255, 0.35) 100%)
		backdrop-filter: blur(8.5px)
		+mq-min(lg)
			padding: r(80px) r(32px)
			margin: 0 r(-32px)
		.tabs-nav
			display: flex
			gap: r(20px)
			li
				a
					+text(white)
					display: flex
					border-bottom: 2px solid transparent
					+trans-all
					text-transform: uppercase
				&.active
					a
						+text(primary-600)
						border-bottom: 2px solid color(primary-600)