.contact-info
	@extend .body-3
	.logo-contact
		a
			display: block
			+flex-width(r(56px))
		p
			@extend .body-1
			font-weight: 700
	ul
		+layout-col(8px)
		li
			display: flex
			gap: r(12px)
			span
				+flex-width(r(16px))
				+text(primary-600)
				position: relative
				top: r(4px)
	.map
		iframe
			height: r(280px)
			width: 100%

.contact-form
	.row
		+gap-override(12px,12px)