.project-list
	> .row
		+gap-override(8px,32px)

.project-item
	&-img
		overflow: hidden
	&-featured
		.project-item-img
			figure
				a
					display: block
					+res-ratio(864,576)
		.project-item-caption
			padding: r(64px)
			+layout-col(24px)
			justify-content: center
			+mq-max(md)
				padding: r(32px)
		.project-item-leading
			margin-top: r(8px) !important
			+line(2)
		.project-item-title
			@extend .heading-5
			font-weight: 700
			text-align: center
			text-transform: uppercase
		.project-item-brief
			@extend .body-3
			+line(10)
		.project-item-divider
			height: 2px
			width: r(40px)
			margin-left: auto
			margin-right: auto
	&-small
		position: relative
		.project-item-img
			figure
				a
					display: block
					+res-ratio(432,324)
		.project-item-caption
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			padding: r(20px) r(40px)
			+layout-col(16px)
			+text(white)
			text-align: center
			background: rgba(0, 0, 0, 0.20)
			pointer-events: none
		.project-item-title
			@extend .body-1
			font-weight: 700
			text-transform: uppercase
		.project-item-brief
			@extend .body-4
			+line(3)
	&-coming
		position: relative
		.project-item-img
			figure
				a
					display: block
					+res-ratio(1312,656)
		.project-item-caption
			position: absolute
			left: r(60px)
			bottom: r(60px)
			width: r(430px)
			padding: r(24px)
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.75) 100%)
			backdrop-filter: blur(10px)
			display: flex
			gap: r(20px)
			+mq-max(md)
				left: r(20px)
				bottom: r(20px)
			+mq-max(sm)
				position: static
				width: 100%
		.project-item-logo
			+flex-width(r(120px))
			figure
				+bg(white)
				+res-ratio(1,1)
				img
					object-fit: contain
					max-height: 80%
					+center(both)
		.project-item-title
			@extend .heading-5
			font-weight: 700
			margin-bottom: r(8px)
			+text(primary-600)
		.project-item-brief
			@extend .body-3
			+line(3)