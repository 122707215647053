.home-bg-1
	position: relative
	position: relative
	&::before
		+pseudo
		bottom: 0
		right: 0
		width: r(572px)
		height: r(693px)
		background-image: url(../img/tree-bg-2.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: center center