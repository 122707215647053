.fixed-stuff
	position: fixed
	z-index: 50
	bottom: r(140px)
	right: r(20px)
	ul
		li
			& + li
				margin-top: r(8px)
			.btn-circle
				+flex-center
				+reset-link
				display: inline-flex
				+circle(60px)
				font-size: r(24px)
				transition: 0.3s all
				flex-direction: column
				gap: r(4px)
				em
					font-size: r(24px)
				img
					max-width: 50%
			&.has-tooltip
				position: relative
				z-index: 1
				.tooltip
					position: absolute
					right: 0
					top: 0
					bottom: 0
					white-space: nowrap
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					+bg(white)
					+text(primary-500)
					border-radius: 999px
					z-index: -1
					padding: 0
					overflow: hidden
					opacity: 0
					pointer-events: none
					+trans-all
					+fz(20px)

				&:hover
					.tooltip
						opacity: 1
						pointer-events: all
						padding: 0 r(80px) 0 r(30px)
