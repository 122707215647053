.home-banner-section
	position: relative
	overflow: hidden
	.banner-item
		position: relative
		&:before
			+pseudo
			+fill
			background: linear-gradient(45deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%)
			z-index: 1
		&-caption
			position: absolute
			z-index: 2
			width: 100%
			bottom: r(124px)
			left: 0
			+text(white)
			+mq-max(md)
				bottom: r(64px)
		&-img
			+mq-max(md)
				figure
					+res-ratio(3,4)
	.swiper-pagination-wrap
		position: absolute
		bottom: r(100px)
		left: 0
		width: 100%
		z-index: 2
		+mq-max(md)
			bottom: r(32px)
	.swiper-pagination
		display: flex
		gap: r(10px)
		position: static
		&-bullet
			opacity: 0.5
			width: r(20px)
			height: r(4px)
			+bg(white)
			border-radius: 0
			+trans-all
			&-active
				opacity: 1
				width: r(60px)
	.banner-overlay
		position: absolute
		bottom: r(-1px)
		left: 0
		width: 100%
		z-index: 2
		figure
			+res-ratio(1920,40)