.section-large
	padding: r(80px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-medium
	padding: r(72px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section
	padding: r(60px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-small
	padding: r(48px) 0
	+mq-max(lg)
		padding: r(40px) 0

section
	&.pb-0
		padding-bottom: 0 !important
	&.pt-0
		padding-top: 0 !important