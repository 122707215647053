.video-item
	&-img
		overflow: hidden
		position: relative
		display: block
		&::before
			+pseudo
			+fill
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%)
			z-index: 1
		figure
			+res-ratio(640,360)
	&-icon
		position: absolute
		+center(both)
		+text(white)
		+fz(64px)
		z-index: 2
		pointer-events: none
	&-caption
		margin-top: r(20px)
		@extend .heading-5
		font-weight: 700
		text-transform: uppercase
		+trans-all
	+on-hover
		.video-item-caption
			+text(primary-600)