.history-year-slider

.history-content-slider
	.history-year-timeline
		position: absolute
		top: r(40px)
		left: 0
		width: 100%
	.history-item
		.content-wrap
			padding-left: 32px
			border-left: 1px solid color(primary-400)
			+mq-max(2xl)
				padding-left: 24px
			+mq-max(lg)
				padding-left: 16px
		.year
			@extend .body-1
			+text(primary-600)
			margin-bottom: r(90px)
