.project-detail
	position: relative
	&::before
		+pseudo
		top: 0
		left: 0
		width: r(476px)
		height: r(800px)
		background-image: url(../img/tree-bg.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: center center
	
.project-stat-number
	margin-top: r(20px)
	ul
		display: flex
		> *
			+flex-width(1 / 3 * 100%)
		li
			padding: r(20px)
			.number
				+text(primary-600)
				@extend .heading-4
				margin-bottom: r(12px)
				white-space: nowrap
			.title 
				@extend .body-3
			& + li
				border-left: 1px solid color(primary-200)

.project-detail-3
	.item-list
		.item
			text-align: center
			+mq-min(md)
				+even
					text-align: left
				+odd
					text-align: right

.project-detail-4
	position: relative
	&::before
		+pseudo
		+fill
		+bg(secondary-700)
		opacity: 0.55
	.content
		position: relative
		z-index: 1