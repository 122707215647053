/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background gradient -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Content margins -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Responsive Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  outline: none;
  box-sizing: border-box; }

img, video, iframe {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

a {
  color: inherit; }

strong, b {
  font-weight: 700; }

input, select, textarea, button {
  font-family: inherit; }

button {
  cursor: pointer; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.7.1
========================================================================== */
.row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.66667rem;
  margin-bottom: -1.66667rem; }

.row > * {
  width: 100%; }

.row > * {
  box-sizing: border-box;
  padding: 0 0 1.66667rem 1.66667rem; }

.col {
  flex: 1 1 0%; }

@media screen and (max-width: 767px) {
  .row {
    margin-left: -1.33333rem;
    margin-bottom: -1.33333rem; }
  .row > * {
    padding: 0 0 1.33333rem 1.33333rem; } }

/************************
    HELPERS SUFFIXES
*************************/
.row.no-gutter {
  margin: 0; }
  .row.no-gutter > * {
    padding: 0; }

.row.equal-height > * {
  align-self: stretch; }
  .row.equal-height > * > * {
    height: 100%; }

/************************
    GRID BY NUMBER
*************************/
.row.row-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row.row-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row.row-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row.row-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row.row-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.row.row-7 > * {
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.row.row-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row.row-9 > * {
  flex: 0 0 11.11111%;
  max-width: 11.11111%; }

.row.row-10 > * {
  flex: 0 0 10%;
  max-width: 10%; }

.row.row-11 > * {
  flex: 0 0 9.09091%;
  max-width: 9.09091%; }

.row.row-12 > * {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 576px) {
  .row.row-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768.98px) {
  .row.row-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024.98px) {
  .row.row-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  .row.row-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1440px) {
  .row.row-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xxl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xxl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xxl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xxl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xxl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xxl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.off-0 {
  margin-left: 0; }

.off-1 {
  margin-left: 8.33333%; }

.off-2 {
  margin-left: 16.66667%; }

.off-3 {
  margin-left: 25%; }

.off-4 {
  margin-left: 33.33333%; }

.off-5 {
  margin-left: 41.66667%; }

.off-6 {
  margin-left: 50%; }

.off-7 {
  margin-left: 58.33333%; }

.off-8 {
  margin-left: 66.66667%; }

.off-9 {
  margin-left: 75%; }

.off-10 {
  margin-left: 83.33333%; }

.off-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768.98px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024.98px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

html {
  font-size: 3.6vw; }
  @media (min-width: 577px) {
    html {
      font-size: 2.4vw; } }
  @media (min-width: 769px) {
    html {
      font-size: 1.5vw; } }
  @media (min-width: 1025px) {
    html {
      font-size: 1.2vw; } }
  @media (min-width: 1441px) {
    html {
      font-size: 1vw; } }

body {
  line-height: 1;
  font-family: 'Signika';
  background: #F9F9F9;
  color: #2b2b2b; }
  @media (min-width: 1281px) {
    body {
      font-size: 0.83333rem; } }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

.swiper-equal-height .swiper-slide {
  height: auto; }
  .swiper-equal-height .swiper-slide > * {
    height: 100%; }

.fixed-stuff {
  position: fixed;
  z-index: 50;
  bottom: 7.29167rem;
  right: 1.04167rem; }
  .fixed-stuff ul li + li {
    margin-top: 0.41667rem; }
  .fixed-stuff ul li .btn-circle {
    align-items: center;
    justify-content: center;
    text-decoration: none;
    display: inline-flex;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 999999px;
    font-size: 1.25rem;
    transition: 0.3s all;
    flex-direction: column;
    gap: 0.20833rem; }
    .fixed-stuff ul li .btn-circle:hover, .fixed-stuff ul li .btn-circle:focus, .fixed-stuff ul li .btn-circle:active {
      text-decoration: none;
      outline: none; }
    .fixed-stuff ul li .btn-circle em {
      font-size: 1.25rem; }
    .fixed-stuff ul li .btn-circle img {
      max-width: 50%; }
  .fixed-stuff ul li.has-tooltip {
    position: relative;
    z-index: 1; }
    .fixed-stuff ul li.has-tooltip .tooltip {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fff;
      color: #ed1c24;
      border-radius: 999px;
      z-index: -1;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      font-size: clamp(12px, 1.04167rem, 1.25rem); }
    .fixed-stuff ul li.has-tooltip:hover .tooltip {
      opacity: 1;
      pointer-events: all;
      padding: 0 4.16667rem 0 1.5625rem; }

.footer-top {
  background-image: linear-gradient(90deg, #b21516 0%, #d71b22 37.5%, #870f15 100%);
  color: #fff; }
  .footer-top h3 {
    font-weight: 400;
    margin-block: 0.41667rem;
    text-transform: uppercase; }

.footer-logo a {
  display: block;
  max-width: 4.16667rem;
  flex: 0 0 4.16667rem;
  width: 100%; }

.footer-bottom h3 {
  font-weight: 700;
  margin-bottom: 1.04167rem;
  text-transform: uppercase; }

.footer-bottom .footer-link ul {
  display: flex;
  flex-direction: column; }
  .footer-bottom .footer-link ul > * + * {
    margin-top: 0.41667rem; }

@media (min-width: 1025px) {
  .footer-bottom .footer-col:not(:last-child) .footer-item {
    border-right: 1px solid #dddddd; } }

.footer-bottom .footer-social ul {
  display: flex;
  gap: 0.41667rem; }
  .footer-bottom .footer-social ul li a {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 0.41667rem;
    color: #555555;
    font-size: clamp(12px, 1.25rem, 1.45833rem); }

.subscribefrm {
  position: relative; }
  .subscribefrm input {
    width: 100%;
    height: 2.08333rem;
    padding: 0 2.5rem 0 1.04167rem;
    border: 0;
    background-color: #fff;
    font-size: 0.83333rem;
    line-height: 1.5; }
    @media (max-width: 1440px) {
      .subscribefrm input {
        font-size: clamp(12px, 0.83333rem, 1.04167rem); } }
    .subscribefrm input::placeholder {
      color: #777777; }
  .subscribefrm button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    color: #b2151b;
    font-size: clamp(12px, 1.04167rem, 1.25rem);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-right: 1.04167rem; }

@media (min-width: 577px) {
  .terms ul {
    display: flex; }
    .terms ul > * + * {
      margin-left: 0.83333rem; }
    .terms ul > * + * {
      padding-left: 0.83333rem;
      border-left: 1px solid #555555; } }

.global-header {
  padding: 0.20833rem 0;
  background-image: linear-gradient(90deg, #b21516 0%, #d71b22 37.5%, #870f15 100%);
  color: #fff;
  position: relative; }

@media (max-width: 1024px) {
  .menu-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-image: linear-gradient(90deg, #b21516 0%, #d71b22 37.5%, #870f15 100%);
    border-top: 1px solid #fff;
    display: none; } }

@media (max-width: 576px) {
  .logo {
    width: 3.75rem; } }

.menu ul {
  display: flex;
  gap: 2.5rem; }
  @media (max-width: 1024px) {
    .menu ul {
      flex-direction: column;
      gap: 1.04167rem;
      padding: 1.66667rem; } }
  .menu ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.20833rem;
    transition: all 0.3s ease-in-out;
    font-weight: 700;
    text-transform: uppercase; }
    @media (max-width: 1024px) {
      .menu ul li a {
        display: inline-flex; } }
  .menu ul li.active a {
    color: #f8a4a7;
    border-bottom: 2px solid #f8a4a7; }
  @media (hover: hover) and (pointer: fine) {
    .menu ul li:hover a {
      color: #f8a4a7; } }

.search-popup {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: none; }
  .search-popup .search-container {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    pointer-events: none; }
  .search-popup .searchbox {
    pointer-events: all;
    position: relative; }
    .search-popup .searchbox input {
      width: 70vw;
      height: 2.5rem;
      padding: 0 3.75rem 0 1.04167rem;
      font-size: clamp(12px, 1.04167rem, 1.25rem); }
    .search-popup .searchbox button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 2.5rem;
      background: transparent;
      border: 0;
      font-size: clamp(12px, 1.04167rem, 1.25rem); }

.header-divider {
  width: 1px;
  height: 0.9375rem;
  background-color: #fff;
  opacity: 0.3; }

.language .current {
  display: flex;
  gap: 0.3125rem;
  align-items: center; }
  .language .current span {
    font-size: 0.72917rem; }
  .language .current .fa-chevron-down {
    opacity: 0.3; }

.language .dropdown-content {
  min-width: auto; }
  .language .dropdown-content ul {
    padding: 0.41667rem;
    display: flex;
    flex-direction: column;
    gap: 0.41667rem;
    color: #2b2b2b; }

.menu-toggle {
  width: 2.29167rem;
  height: 2.29167rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #770e12;
  border-radius: 0.41667rem; }

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.5;
  background-color: #090909;
  z-index: 1000;
  display: none; }

.search-page {
  padding: 3.125rem 0; }
  @media (max-width: 1024px) {
    .search-page {
      padding: 2.08333rem 0; } }
  .search-page h1 {
    margin-bottom: 1.04167rem;
    font-size: 2.08333rem;
    line-height: 1.2;
    font-weight: 700;
    color: #333333; }
    @media (max-width: 1440px) {
      .search-page h1 {
        font-size: clamp(12px, 2.08333rem, 2.29167rem); } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f002';
      font-family: "Font Awesome 6 Pro";
      top: 0;
      right: 0.52083rem;
      z-index: 1;
      font-size: 1.25rem;
      width: 3.125rem;
      height: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: center; }
  .search-page .searchcontrols .frm-btn, .search-page .searchcontrols input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0.52083rem;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    width: 3.125rem;
    height: 3.125rem;
    min-width: auto; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 1.04167rem;
    padding-right: 2.8125rem;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 3.125rem !important;
    border-radius: 1.5625rem !important;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.08333rem;
    outline: none;
    width: 2.29167rem;
    height: 2.29167rem;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 1.04167rem;
    font-size: 0.72917rem;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 1.5625rem; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 1.5625rem; }
  .search-page .searchresult {
    margin-bottom: 1.5625rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 1rem;
    border-radius: 0.5rem; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 0.9375rem;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 0.52083rem; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 0.52083rem; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.AspNet-TreeView {
  line-height: 1.5; }
  .AspNet-TreeView > ul {
    columns: 3 auto;
    margin: 10px 0 0;
    padding-bottom: 25px; }
    .AspNet-TreeView > ul > .AspNet-TreeView-Root {
      margin-bottom: 26px;
      break-inside: avoid-column; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > a {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 7px; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
        display: none; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li {
        padding-bottom: 7px;
        font-size: 18px;
        color: #06c; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li > a {
          font-weight: 500;
          display: inline-block; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > a {
          margin-bottom: 7px; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul {
          list-style: disc;
          padding-left: 1.25em; }
          .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul > li {
            font-size: 16px;
            margin-bottom: 7px; }
  @media screen and (max-width: 768.98px) {
    .AspNet-TreeView > ul {
      column-count: 1; } }

.sitemap {
  padding: 2.08333rem 0; }
  .sitemap a:hover {
    text-decoration: underline; }

.sitemap-heading {
  height: 2.70833rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 1.66667rem; }
  .sitemap-heading h1 {
    font-weight: 600;
    font-size: 1.66667rem;
    line-height: 1.5; }

.breadcrumb-wrapper {
  background: linear-gradient(152deg, #FDF3EC 0%, #FAF4C4 100%);
  color: #555555; }
  @media (max-width: 1024px) {
    .breadcrumb-wrapper {
      display: none; } }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0.41667rem 0;
    display: flex;
    align-items: center; }
    .breadcrumb-wrapper .breadcrumb li a {
      font-size: 0.72917rem;
      line-height: 1.42857;
      display: flex;
      align-items: center;
      font-weight: 500; }
      @media (max-width: 1440px) {
        .breadcrumb-wrapper .breadcrumb li a {
          font-size: clamp(12px, 0.72917rem, 0.9375rem); } }
    .breadcrumb-wrapper .breadcrumb li + li {
      padding-left: 0.625rem;
      display: flex;
      align-items: center; }
      .breadcrumb-wrapper .breadcrumb li + li::before {
        content: "/";
        margin-right: 0.625rem;
        font-size: 0.72917rem; }
    .breadcrumb-wrapper .breadcrumb li:first-child a {
      font-size: 0; }
      .breadcrumb-wrapper .breadcrumb li:first-child a:before {
        content: '\f015';
        font-family: 'Font Awesome 6 Pro';
        font-size: 0.72917rem;
        line-height: 1.42857;
        font-weight: 400; }
        @media (max-width: 1440px) {
          .breadcrumb-wrapper .breadcrumb li:first-child a:before {
            font-size: clamp(12px, 0.72917rem, 0.9375rem); } }

.career-policy-list .item {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .career-policy-list .item > * + * {
    margin-top: 1.66667rem; }
  .career-policy-list .item .icon figure {
    width: 3.64583rem;
    margin: 0 auto;
    position: relative;
    padding-top: 100%; }
    .career-policy-list .item .icon figure img, .career-policy-list .item .icon figure iframe, .career-policy-list .item .icon figure video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .career-policy-list .item .icon figure img {
      object-fit: contain; }

.career-policy-list .text-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.5; }

@media (max-width: 1024px) {
  .career-list {
    overflow: auto; }
    .career-list table {
      width: 200%; } }

.career-list table {
  counter-reset: section;
  width: 100%;
  background-color: #fff;
  text-align: left; }
  .career-list table th, .career-list table td {
    padding: 0.625rem 1.04167rem;
    border-bottom: 1px solid #dddddd; }
  .career-list table tbody tr .num::before {
    counter-increment: section;
    content: counters(section, ".", decimal-leading-zero); }
  .career-list table th {
    font-weight: 700;
    color: #b2151b; }

.contact-info .logo-contact a {
  display: block;
  max-width: 2.91667rem;
  flex: 0 0 2.91667rem;
  width: 100%; }

.contact-info .logo-contact p {
  font-weight: 700; }

.contact-info ul {
  display: flex;
  flex-direction: column; }
  .contact-info ul > * + * {
    margin-top: 0.41667rem; }
  .contact-info ul li {
    display: flex;
    gap: 0.625rem; }
    .contact-info ul li span {
      max-width: 0.83333rem;
      flex: 0 0 0.83333rem;
      width: 100%;
      color: #b2151b;
      position: relative;
      top: 0.20833rem; }

.contact-info .map iframe {
  height: 14.58333rem;
  width: 100%; }

.contact-form .row {
  margin-left: -0.625rem;
  margin-bottom: -0.625rem; }
  .contact-form .row > * {
    padding-left: 0.625rem;
    padding-bottom: 0.625rem; }

.gallery-item-img {
  display: block;
  position: relative;
  overflow: hidden; }
  .gallery-item-img figure {
    position: relative;
    padding-top: 56.25%; }
    .gallery-item-img figure img, .gallery-item-img figure iframe, .gallery-item-img figure video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }

.gallery-item-caption {
  position: absolute;
  padding: 0 1.04167rem;
  bottom: 1.66667rem;
  text-align: center;
  width: 100%;
  left: 0;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase; }
  @media (min-width: 1025px) {
    .gallery-item-caption {
      transform: translateY(50%);
      opacity: 0;
      transition: all 0.3s ease-in-out; } }

@media (hover: hover) and (pointer: fine) {
  .gallery-item:hover .gallery-item-caption {
    transform: none;
    opacity: 1; } }

.subnav ul {
  font-size: 0;
  letter-spacing: 0;
  margin: 0 auto;
  padding: 0;
  word-spacing: 0;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  position: relative; }
  .subnav ul li {
    letter-spacing: normal;
    word-spacing: normal;
    display: inline-block;
    vertical-align: middle; }
    .subnav ul li + li {
      margin-left: 0.625rem; }
    .subnav ul li a {
      font-weight: 700;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      padding: 0 0.83333rem;
      border-radius: 0.41667rem; }
    .subnav ul li.active a {
      background-color: #b2151b;
      color: #fff; }
    @media (hover: hover) and (pointer: fine) {
      .subnav ul li:hover a {
        background-color: #b2151b;
        color: #fff; } }

.video-item-img {
  overflow: hidden;
  position: relative;
  display: block; }
  .video-item-img::before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
    z-index: 1; }
  .video-item-img figure {
    position: relative;
    padding-top: 56.25%; }
    .video-item-img figure img, .video-item-img figure iframe, .video-item-img figure video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }

.video-item-icon {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: clamp(12px, 3.33333rem, 3.54167rem);
  z-index: 2;
  pointer-events: none; }

.video-item-caption {
  margin-top: 1.04167rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out; }

@media (hover: hover) and (pointer: fine) {
  .video-item:hover .video-item-caption {
    color: #b2151b; } }

.about-section-1 {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat; }
  @media (max-width: 768px) {
    .about-section-1 {
      padding-bottom: 7.29167rem !important; } }
  @media (min-width: 769px) {
    .about-section-1 {
      min-height: 45.625rem; } }
  .about-section-1 .content {
    overflow: auto;
    padding-right: 1.45833rem;
    scrollbar-width: thin;
    max-height: 20.83333rem; }
    .about-section-1 .content::-webkit-scrollbar {
      width: 0.20833rem;
      height: 0.20833rem; }
    .about-section-1 .content::-webkit-scrollbar-thumb {
      background: #b2151b; }
    .about-section-1 .content::-webkit-scrollbar-track {
      background: #dddddd; }
    @media (min-width: 1025px) {
      .about-section-1 .content {
        max-height: 26.875rem;
        text-align: center; } }

.history-content-slider .history-year-timeline {
  position: absolute;
  top: 2.08333rem;
  left: 0;
  width: 100%; }

.history-content-slider .history-item .content-wrap {
  padding-left: 32px;
  border-left: 1px solid #f14950; }
  @media (max-width: 1440px) {
    .history-content-slider .history-item .content-wrap {
      padding-left: 24px; } }
  @media (max-width: 1024px) {
    .history-content-slider .history-item .content-wrap {
      padding-left: 16px; } }

.history-content-slider .history-item .year {
  color: #b2151b;
  margin-bottom: 4.6875rem; }

.about-section-3 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2.08333rem 0; }
  @media (min-width: 1025px) {
    .about-section-3 {
      background-size: contain;
      min-height: 41.66667rem;
      display: flex;
      align-items: center; }
      .about-section-3 > * {
        width: 100%; } }
  .about-section-3 .content {
    padding: 2.08333rem 1.66667rem;
    border-radius: 0.83333rem;
    background: linear-gradient(45deg, rgba(234, 234, 234, 0.35) 0%, rgba(255, 255, 255, 0.35) 100%);
    backdrop-filter: blur(8.5px); }
    @media (min-width: 1025px) {
      .about-section-3 .content {
        padding: 4.16667rem 1.66667rem;
        margin: 0 -1.66667rem; } }
    .about-section-3 .content .tabs-nav {
      display: flex;
      gap: 1.04167rem; }
      .about-section-3 .content .tabs-nav li a {
        color: #fff;
        display: flex;
        border-bottom: 2px solid transparent;
        transition: all 0.3s ease-in-out;
        text-transform: uppercase; }
      .about-section-3 .content .tabs-nav li.active a {
        color: #b2151b;
        border-bottom: 2px solid #b2151b; }

.about-section-4 {
  position: relative; }
  .about-section-4::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 29.79167rem;
    height: 36.09375rem;
    background-image: url(../img/tree-bg-2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }
  .about-section-4 .heading-1, .about-section-4 .event-item-date .date-box .day, .event-item-date .date-box .about-section-4 .day {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(180deg, #B2151B 0%, #770E12 100%); }

.about-section-7 .content {
  padding: 2.08333rem 1.66667rem;
  border-radius: 0.83333rem;
  background: linear-gradient(45deg, rgba(234, 234, 234, 0.35) 0%, rgba(255, 255, 255, 0.35) 100%);
  backdrop-filter: blur(8.5px); }

.brand-slider .item .img figure {
  position: relative;
  padding-top: 68.75%; }
  .brand-slider .item .img figure img, .brand-slider .item .img figure iframe, .brand-slider .item .img figure video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .brand-slider .item .img figure img {
    object-fit: contain; }

.event-item {
  position: relative; }
  @media (min-width: 769px) {
    .event-item {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1.66667rem;
      margin-bottom: -1.66667rem; }
      .event-item > * {
        padding-left: 1.66667rem;
        padding-bottom: 1.66667rem; } }
  @media (max-width: 1024px) {
    .event-item-date {
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
      z-index: 1; } }
  @media (min-width: 1025px) {
    .event-item-date {
      max-width: 16.66667%;
      flex: 0 0 16.66667%;
      width: 100%; } }
  .event-item-date .date-box {
    text-align: center;
    color: #b2151b; }
    .event-item-date .date-box .day {
      font-weight: 700;
      padding: 2.08333rem 0 1.04167rem;
      border-bottom: 1px solid #dddddd; }
      @media (max-width: 1024px) {
        .event-item-date .date-box .day {
          padding: 0;
          font-size: 1.45833rem;
          line-height: 1.14286;
          padding-bottom: 0.625rem; } }
  @media (max-width: 1024px) and (max-width: 1440px) {
    .event-item-date .date-box .day {
      font-size: clamp(12px, 1.45833rem, 1.66667rem); } }
    .event-item-date .date-box .month {
      padding-top: 1.04167rem; }
      @media (max-width: 1024px) {
        .event-item-date .date-box .month {
          padding-top: 0.625rem; } }
    @media (max-width: 1024px) {
      .event-item-date .date-box {
        padding: 0.625rem;
        background-color: #fff; } }
  @media (min-width: 769px) {
    .event-item-img {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
      width: 100%; } }
  .event-item-img figure {
    border-radius: 0.625rem;
    overflow: hidden; }
  @media (min-width: 769px) {
    .event-item-caption {
      max-width: 66.66667%;
      flex: 0 0 66.66667%;
      width: 100%; } }
  @media (min-width: 1025px) {
    .event-item-caption {
      max-width: 50%;
      flex: 0 0 50%;
      width: 100%; } }
  .event-item-caption-inner {
    background-color: #fff;
    border-radius: 0.625rem;
    padding: 1.25rem; }
    @media (min-width: 769px) {
      .event-item-caption-inner {
        padding: 3.125rem 1.66667rem 3.125rem 3.33333rem;
        margin-left: -1.66667rem;
        height: 100%; } }
  .event-item-title {
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
  .event-item-meta {
    color: #999999; }
  .event-item-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical; }
  @media (hover: hover) and (pointer: fine) {
    .event-item:hover .event-item-title {
      color: #b2151b; } }

.event-detail-info ul {
  display: flex;
  flex-direction: column; }
  .event-detail-info ul > * + * {
    margin-top: 1.25rem; }
  .event-detail-info ul li {
    display: flex;
    flex-direction: column; }
    .event-detail-info ul li > * + * {
      margin-top: 0.625rem; }
    .event-detail-info ul li strong {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #b2151b;
      gap: 1.04167rem; }
      .event-detail-info ul li strong::after {
        content: '';
        max-width: 60%;
        flex: 0 0 60%;
        width: 100%;
        height: 1px;
        background-color: #bbbbbb; }
    .event-detail-info ul li span {
      color: #404040; }

.home-banner-section {
  position: relative;
  overflow: hidden; }
  .home-banner-section .banner-item {
    position: relative; }
    .home-banner-section .banner-item:before {
      content: "";
      display: block;
      position: absolute;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 1; }
    .home-banner-section .banner-item-caption {
      position: absolute;
      z-index: 2;
      width: 100%;
      bottom: 6.45833rem;
      left: 0;
      color: #fff; }
      @media (max-width: 768px) {
        .home-banner-section .banner-item-caption {
          bottom: 3.33333rem; } }
    @media (max-width: 768px) {
      .home-banner-section .banner-item-img figure {
        position: relative;
        padding-top: 133.33333%; }
        .home-banner-section .banner-item-img figure img, .home-banner-section .banner-item-img figure iframe, .home-banner-section .banner-item-img figure video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; } }
  .home-banner-section .swiper-pagination-wrap {
    position: absolute;
    bottom: 5.20833rem;
    left: 0;
    width: 100%;
    z-index: 2; }
    @media (max-width: 768px) {
      .home-banner-section .swiper-pagination-wrap {
        bottom: 1.66667rem; } }
  .home-banner-section .swiper-pagination {
    display: flex;
    gap: 0.52083rem;
    position: static; }
    .home-banner-section .swiper-pagination-bullet {
      opacity: 0.5;
      width: 1.04167rem;
      height: 0.20833rem;
      background-color: #fff;
      border-radius: 0;
      transition: all 0.3s ease-in-out; }
      .home-banner-section .swiper-pagination-bullet-active {
        opacity: 1;
        width: 3.125rem; }
  .home-banner-section .banner-overlay {
    position: absolute;
    bottom: -0.05208rem;
    left: 0;
    width: 100%;
    z-index: 2; }
    .home-banner-section .banner-overlay figure {
      position: relative;
      padding-top: 2.08333%; }
      .home-banner-section .banner-overlay figure img, .home-banner-section .banner-overlay figure iframe, .home-banner-section .banner-overlay figure video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }

.home-section-1 {
  position: relative; }
  .home-section-1 .item {
    display: flex;
    flex-direction: column;
    padding: 1.45833rem 1.66667rem;
    border-radius: 0.83333rem;
    box-shadow: 4px 6px 24px 0px rgba(0, 0, 0, 0.04); }
    .home-section-1 .item > * + * {
      margin-top: 0.625rem; }
    @media (max-width: 576px) {
      .home-section-1 .item {
        padding: 1.25rem; } }
    .home-section-1 .item .icon {
      width: 4.16667rem; }
      .home-section-1 .item .icon figure {
        margin: 0 auto;
        position: relative;
        padding-top: 100%; }
        .home-section-1 .item .icon figure img, .home-section-1 .item .icon figure iframe, .home-section-1 .item .icon figure video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .home-section-1 .item .icon figure img {
          object-fit: contain; }
    .home-section-1 .item .number {
      color: #b2151b; }
  .home-section-1 .text-bg {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0; }
  .home-section-1 .content-container {
    z-index: 2; }

.home-bg-1 {
  position: relative;
  position: relative; }
  .home-bg-1::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 29.79167rem;
    height: 36.09375rem;
    background-image: url(../img/tree-bg-2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }

.home-bg-2 {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat; }

.gallery-slider-images .img figure {
  position: relative;
  padding-top: 50%; }
  .gallery-slider-images .img figure img, .gallery-slider-images .img figure iframe, .gallery-slider-images .img figure video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.gallery-slider-thumbnail .img {
  background-color: #fff;
  transition: all 0.3s ease-in-out; }
  .gallery-slider-thumbnail .img figure {
    position: relative;
    padding-top: 70.3125%; }
    .gallery-slider-thumbnail .img figure img, .gallery-slider-thumbnail .img figure iframe, .gallery-slider-thumbnail .img figure video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .gallery-slider-thumbnail .img figcaption {
    padding: 0.83333rem;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #b2151b;
    color: #b2151b;
    transition: all 0.3s ease-in-out; }

.gallery-slider-thumbnail .swiper-slide-thumb-active .img {
  background-color: #b2151b; }
  .gallery-slider-thumbnail .swiper-slide-thumb-active .img figcaption {
    color: #fff; }

.news-item {
  background-color: #fff;
  border-radius: 0.625rem;
  overflow: hidden; }
  .news-item-meta {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #999999; }
    .news-item-meta span {
      display: inline-block;
      width: 2px;
      height: 2px;
      background-color: #777777; }
  .news-item-img {
    overflow: hidden; }
  .news-item-cate {
    font-weight: 700; }
  .news-item-title {
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media (max-width: 768px) {
      .news-item-title {
        font-size: 1.04167rem;
        line-height: 1.4; } }
  @media (max-width: 768px) and (max-width: 1440px) {
    .news-item-title {
      font-size: clamp(12px, 1.04167rem, 1.25rem); } }
  .news-item-big .news-item-caption {
    padding: 2.08333rem; }
    @media (max-width: 768px) {
      .news-item-big .news-item-caption {
        padding: 1.25rem; } }
  .news-item-horizontal {
    display: flex; }
    .news-item-horizontal .news-item-img {
      max-width: 32.69231%;
      flex: 0 0 32.69231%;
      width: 100%; }
      @media (max-width: 768px) {
        .news-item-horizontal .news-item-img {
          max-width: 33.33333%;
          flex: 0 0 33.33333%;
          width: 100%; } }
    @media (min-width: 769px) {
      .news-item-horizontal .news-item-title {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; } }
  .news-item-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical; }
  @media (hover: hover) and (pointer: fine) {
    .news-item:hover .news-item-title {
      color: #b2151b; } }

@media (max-width: 768px) {
  .news-list .news-item-small {
    display: flex; }
    .news-list .news-item-small .news-item-img {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
      width: 100%; } }

.detail-meta {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: #999999;
  padding-bottom: 1.04167rem;
  border-bottom: 1px solid #bbbbbb; }
  .detail-meta span {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #777777; }

.project-detail {
  position: relative; }
  .project-detail::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 24.79167rem;
    height: 41.66667rem;
    background-image: url(../img/tree-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }

.project-stat-number {
  margin-top: 1.04167rem; }
  .project-stat-number ul {
    display: flex; }
    .project-stat-number ul > * {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
      width: 100%; }
    .project-stat-number ul li {
      padding: 1.04167rem; }
      .project-stat-number ul li .number {
        color: #b2151b;
        margin-bottom: 0.625rem;
        white-space: nowrap; }
      .project-stat-number ul li + li {
        border-left: 1px solid #f8a4a7; }

.project-detail-3 .item-list .item {
  text-align: center; }
  @media (min-width: 769px) {
    .project-detail-3 .item-list .item:nth-child(even) {
      text-align: left; }
    .project-detail-3 .item-list .item:nth-child(odd) {
      text-align: right; } }

.project-detail-4 {
  position: relative; }
  .project-detail-4::before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #005329;
    opacity: 0.55; }
  .project-detail-4 .content {
    position: relative;
    z-index: 1; }

.project-list > .row {
  margin-left: -0.41667rem;
  margin-bottom: -1.66667rem; }
  .project-list > .row > * {
    padding-left: 0.41667rem;
    padding-bottom: 1.66667rem; }

.project-item-img {
  overflow: hidden; }

.project-item-featured .project-item-img figure a {
  display: block;
  position: relative;
  padding-top: 66.66667%; }
  .project-item-featured .project-item-img figure a img, .project-item-featured .project-item-img figure a iframe, .project-item-featured .project-item-img figure a video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.project-item-featured .project-item-caption {
  padding: 3.33333rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .project-item-featured .project-item-caption > * + * {
    margin-top: 1.25rem; }
  @media (max-width: 768px) {
    .project-item-featured .project-item-caption {
      padding: 1.66667rem; } }

.project-item-featured .project-item-leading {
  margin-top: 0.41667rem !important;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.project-item-featured .project-item-title {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }

.project-item-featured .project-item-brief {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical; }

.project-item-featured .project-item-divider {
  height: 2px;
  width: 2.08333rem;
  margin-left: auto;
  margin-right: auto; }

.project-item-small {
  position: relative; }
  .project-item-small .project-item-img figure a {
    display: block;
    position: relative;
    padding-top: 75%; }
    .project-item-small .project-item-img figure a img, .project-item-small .project-item-img figure a iframe, .project-item-small .project-item-img figure a video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .project-item-small .project-item-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.04167rem 2.08333rem;
    display: flex;
    flex-direction: column;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none; }
    .project-item-small .project-item-caption > * + * {
      margin-top: 0.83333rem; }
  .project-item-small .project-item-title {
    font-weight: 700;
    text-transform: uppercase; }
  .project-item-small .project-item-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }

.project-item-coming {
  position: relative; }
  .project-item-coming .project-item-img figure a {
    display: block;
    position: relative;
    padding-top: 50%; }
    .project-item-coming .project-item-img figure a img, .project-item-coming .project-item-img figure a iframe, .project-item-coming .project-item-img figure a video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .project-item-coming .project-item-caption {
    position: absolute;
    left: 3.125rem;
    bottom: 3.125rem;
    width: 22.39583rem;
    padding: 1.25rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0.75) 100%);
    backdrop-filter: blur(10px);
    display: flex;
    gap: 1.04167rem; }
    @media (max-width: 768px) {
      .project-item-coming .project-item-caption {
        left: 1.04167rem;
        bottom: 1.04167rem; } }
    @media (max-width: 576px) {
      .project-item-coming .project-item-caption {
        position: static;
        width: 100%; } }
  .project-item-coming .project-item-logo {
    max-width: 6.25rem;
    flex: 0 0 6.25rem;
    width: 100%; }
    .project-item-coming .project-item-logo figure {
      background-color: #fff;
      position: relative;
      padding-top: 100%; }
      .project-item-coming .project-item-logo figure img, .project-item-coming .project-item-logo figure iframe, .project-item-coming .project-item-logo figure video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .project-item-coming .project-item-logo figure img {
        object-fit: contain;
        max-height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .project-item-coming .project-item-title {
    font-weight: 700;
    margin-bottom: 0.41667rem;
    color: #b2151b; }
  .project-item-coming .project-item-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }

.article-content > * + * {
  margin-top: 0.625rem; }

.article-content ul {
  list-style: disc;
  padding-left: 1.25rem; }

.article-content table td, .article-content table th {
  border: 1px solid #dee2e6;
  padding: .75rem;
  vertical-align: top; }

.article-content h2 {
  font-weight: 700;
  color: #b2151b; }

.article-content h3 {
  font-size: 0.9375rem;
  font-weight: 600; }

.article-content * + h2 {
  margin-top: 0.83333rem; }

.expand-content {
  overflow: hidden; }
  .expand-content.show {
    max-height: none !important; }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-sm-gray {
    border: 1px solid #DDDDDD; } }

.border-primary {
  border: 1px solid #b2151b; }

@media (min-width: 576px) {
  .border-sm-primary {
    border: 1px solid #b2151b; } }

.border-none {
  border: none; }

@media (min-width: 576px) {
  .border-sm-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 576px) {
  .border-sm-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-top-sm-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary {
  border-top: 1px solid #b2151b; }

@media (min-width: 576px) {
  .border-top-sm-primary {
    border-top: 1px solid #b2151b; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-right-sm-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary {
  border-right: 1px solid #b2151b; }

@media (min-width: 576px) {
  .border-right-sm-primary {
    border-right: 1px solid #b2151b; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-bottom-sm-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary {
  border-bottom: 1px solid #b2151b; }

@media (min-width: 576px) {
  .border-bottom-sm-primary {
    border-bottom: 1px solid #b2151b; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 576px) {
  .border-left-sm-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary {
  border-left: 1px solid #b2151b; }

@media (min-width: 576px) {
  .border-left-sm-primary {
    border-left: 1px solid #b2151b; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-md-gray {
    border: 1px solid #DDDDDD; } }

.border-primary {
  border: 1px solid #b2151b; }

@media (min-width: 768px) {
  .border-md-primary {
    border: 1px solid #b2151b; } }

.border-none {
  border: none; }

@media (min-width: 768px) {
  .border-md-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 768px) {
  .border-md-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-top-md-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary {
  border-top: 1px solid #b2151b; }

@media (min-width: 768px) {
  .border-top-md-primary {
    border-top: 1px solid #b2151b; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-right-md-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary {
  border-right: 1px solid #b2151b; }

@media (min-width: 768px) {
  .border-right-md-primary {
    border-right: 1px solid #b2151b; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-bottom-md-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary {
  border-bottom: 1px solid #b2151b; }

@media (min-width: 768px) {
  .border-bottom-md-primary {
    border-bottom: 1px solid #b2151b; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 768px) {
  .border-left-md-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary {
  border-left: 1px solid #b2151b; }

@media (min-width: 768px) {
  .border-left-md-primary {
    border-left: 1px solid #b2151b; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-lg-gray {
    border: 1px solid #DDDDDD; } }

.border-primary {
  border: 1px solid #b2151b; }

@media (min-width: 1024px) {
  .border-lg-primary {
    border: 1px solid #b2151b; } }

.border-none {
  border: none; }

@media (min-width: 1024px) {
  .border-lg-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1024px) {
  .border-lg-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-top-lg-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary {
  border-top: 1px solid #b2151b; }

@media (min-width: 1024px) {
  .border-top-lg-primary {
    border-top: 1px solid #b2151b; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-right-lg-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary {
  border-right: 1px solid #b2151b; }

@media (min-width: 1024px) {
  .border-right-lg-primary {
    border-right: 1px solid #b2151b; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-bottom-lg-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary {
  border-bottom: 1px solid #b2151b; }

@media (min-width: 1024px) {
  .border-bottom-lg-primary {
    border-bottom: 1px solid #b2151b; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1024px) {
  .border-left-lg-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary {
  border-left: 1px solid #b2151b; }

@media (min-width: 1024px) {
  .border-left-lg-primary {
    border-left: 1px solid #b2151b; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-xl-gray {
    border: 1px solid #DDDDDD; } }

.border-primary {
  border: 1px solid #b2151b; }

@media (min-width: 1280px) {
  .border-xl-primary {
    border: 1px solid #b2151b; } }

.border-none {
  border: none; }

@media (min-width: 1280px) {
  .border-xl-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1280px) {
  .border-xl-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-top-xl-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary {
  border-top: 1px solid #b2151b; }

@media (min-width: 1280px) {
  .border-top-xl-primary {
    border-top: 1px solid #b2151b; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-right-xl-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary {
  border-right: 1px solid #b2151b; }

@media (min-width: 1280px) {
  .border-right-xl-primary {
    border-right: 1px solid #b2151b; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-bottom-xl-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary {
  border-bottom: 1px solid #b2151b; }

@media (min-width: 1280px) {
  .border-bottom-xl-primary {
    border-bottom: 1px solid #b2151b; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1280px) {
  .border-left-xl-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary {
  border-left: 1px solid #b2151b; }

@media (min-width: 1280px) {
  .border-left-xl-primary {
    border-left: 1px solid #b2151b; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-2xl-gray {
    border: 1px solid #DDDDDD; } }

.border-primary {
  border: 1px solid #b2151b; }

@media (min-width: 1440px) {
  .border-2xl-primary {
    border: 1px solid #b2151b; } }

.border-none {
  border: none; }

@media (min-width: 1440px) {
  .border-2xl-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1440px) {
  .border-2xl-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-top-2xl-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary {
  border-top: 1px solid #b2151b; }

@media (min-width: 1440px) {
  .border-top-2xl-primary {
    border-top: 1px solid #b2151b; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-right-2xl-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary {
  border-right: 1px solid #b2151b; }

@media (min-width: 1440px) {
  .border-right-2xl-primary {
    border-right: 1px solid #b2151b; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-bottom-2xl-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary {
  border-bottom: 1px solid #b2151b; }

@media (min-width: 1440px) {
  .border-bottom-2xl-primary {
    border-bottom: 1px solid #b2151b; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1440px) {
  .border-left-2xl-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary {
  border-left: 1px solid #b2151b; }

@media (min-width: 1440px) {
  .border-left-2xl-primary {
    border-left: 1px solid #b2151b; } }

.border-gray {
  border: 1px solid #DDDDDD; }

@media (min-width: 1600px) {
  .border-3xl-gray {
    border: 1px solid #DDDDDD; } }

.border-primary {
  border: 1px solid #b2151b; }

@media (min-width: 1600px) {
  .border-3xl-primary {
    border: 1px solid #b2151b; } }

.border-none {
  border: none; }

@media (min-width: 1600px) {
  .border-3xl-none {
    border: none; } }

.border-none {
  border: none; }

@media (min-width: 1600px) {
  .border-3xl-none {
    border: none; } }

.border-top-gray {
  border-top: 1px solid #DDDDDD; }

@media (min-width: 1600px) {
  .border-top-3xl-gray {
    border-top: 1px solid #DDDDDD; } }

.border-top-primary {
  border-top: 1px solid #b2151b; }

@media (min-width: 1600px) {
  .border-top-3xl-primary {
    border-top: 1px solid #b2151b; } }

.border-right-gray {
  border-right: 1px solid #DDDDDD; }

@media (min-width: 1600px) {
  .border-right-3xl-gray {
    border-right: 1px solid #DDDDDD; } }

.border-right-primary {
  border-right: 1px solid #b2151b; }

@media (min-width: 1600px) {
  .border-right-3xl-primary {
    border-right: 1px solid #b2151b; } }

.border-bottom-gray {
  border-bottom: 1px solid #DDDDDD; }

@media (min-width: 1600px) {
  .border-bottom-3xl-gray {
    border-bottom: 1px solid #DDDDDD; } }

.border-bottom-primary {
  border-bottom: 1px solid #b2151b; }

@media (min-width: 1600px) {
  .border-bottom-3xl-primary {
    border-bottom: 1px solid #b2151b; } }

.border-left-gray {
  border-left: 1px solid #DDDDDD; }

@media (min-width: 1600px) {
  .border-left-3xl-gray {
    border-left: 1px solid #DDDDDD; } }

.border-left-primary {
  border-left: 1px solid #b2151b; }

@media (min-width: 1600px) {
  .border-left-3xl-primary {
    border-left: 1px solid #b2151b; } }

.btn-primary {
  background-color: #b2151b;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-primary:hover {
      background-color: #841014; } }

.btn-border-primary {
  border: 1px solid #b2151b;
  color: #b2151b; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-primary:hover {
      background-color: #b2151b;
      color: #fff; } }

.btn-secondary {
  background-color: #D3ECDB;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-secondary:hover {
      background-color: #afddbe; } }

.btn-border-secondary {
  border: 1px solid #D3ECDB;
  color: #D3ECDB; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-secondary:hover {
      background-color: #D3ECDB;
      color: #333; } }

.btn-white {
  background-color: #fff;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-white:hover {
      background-color: #e6e6e6; } }

.btn-border-white {
  border: 1px solid #fff;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-white:hover {
      background-color: #fff;
      color: #333; } }

.btn {
  padding: 0.41667rem 0.83333rem;
  display: inline-flex;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  font-size: 0.83333rem;
  line-height: 1.5;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  gap: 0.41667rem;
  border-radius: 0.41667rem; }
  @media (max-width: 1440px) {
    .btn {
      font-size: clamp(12px, 0.83333rem, 1.04167rem); } }
  .btn-pill {
    border-radius: 999px; }
  .btn-lg {
    padding: 0 0.83333rem 0 1.25rem;
    gap: 1.04167rem;
    height: 2.29167rem; }

.swiper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  color: #b2151b;
  font-size: 1.66667rem;
  cursor: pointer;
  z-index: 2;
  transition: 0.3s all;
  border: 1px solid #b2151b;
  border-radius: 0.41667rem; }
  @media (max-width: 1024px) {
    .swiper-btn {
      display: none; } }
  @media (hover: hover) and (pointer: fine) {
    .swiper-btn:hover {
      background-color: #b2151b;
      color: #fff; } }
  .swiper-btn.swiper-button-disabled {
    opacity: 0;
    pointer-events: none; }
  .swiper-btn.no-box {
    width: auto;
    height: auto;
    background: transparent;
    color: #b2151b; }
    @media (hover: hover) and (pointer: fine) {
      .swiper-btn.no-box:hover {
        color: #b2151b; } }

.swiper-navigation.is-between .swiper-btn {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 1.5625rem; }
  .swiper-navigation.is-between .swiper-btn.swiper-prev {
    right: 100%; }
  .swiper-navigation.is-between .swiper-btn.swiper-next {
    left: 100%; }
  @media (max-width: 1440px) {
    .swiper-navigation.is-between .swiper-btn {
      margin: 0 0.52083rem; } }

.swiper-navigation.is-between.is-no-gap .swiper-btn {
  margin: 0; }
  .swiper-navigation.is-between.is-no-gap .swiper-btn.swiper-prev {
    right: auto;
    left: 0; }
  .swiper-navigation.is-between.is-no-gap .swiper-btn.swiper-next {
    left: auto;
    right: 0; }

.swiper-navigation.is-left {
  display: flex;
  gap: 0.625rem; }

.container {
  width: 100%;
  max-width: 96vw;
  padding-right: 0.83333rem;
  padding-left: 0.83333rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 577px) {
    .container {
      max-width: 90vw; } }
  @media (min-width: 769px) {
    .container {
      max-width: 96vw; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 90vw; } }
  @media (min-width: 1281px) {
    .container {
      max-width: 70rem; } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  right: 0;
  z-index: 1; }
  .dropdown-content > * {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px; }

.dropdown:hover .dropdown-content {
  display: block; }

.fz-12 {
  font-size: clamp(12px, 0.625rem, 16px); }

.fz-14 {
  font-size: clamp(12px, 0.72917rem, 18px); }

.fz-16 {
  font-size: clamp(12px, 0.83333rem, 20px); }

.fz-18 {
  font-size: clamp(12px, 0.9375rem, 22px); }

.fz-20 {
  font-size: clamp(12px, 1.04167rem, 24px); }

.fz-22 {
  font-size: clamp(12px, 1.14583rem, 26px); }

.fz-24 {
  font-size: clamp(12px, 1.25rem, 28px); }

.fz-26 {
  font-size: clamp(12px, 1.35417rem, 30px); }

.fz-28 {
  font-size: clamp(12px, 1.45833rem, 32px); }

.fz-30 {
  font-size: clamp(12px, 1.5625rem, 34px); }

.fz-32 {
  font-size: clamp(12px, 1.66667rem, 36px); }

.fz-34 {
  font-size: clamp(12px, 1.77083rem, 38px); }

.fz-36 {
  font-size: clamp(12px, 1.875rem, 40px); }

.fz-38 {
  font-size: clamp(12px, 1.97917rem, 42px); }

.fz-40 {
  font-size: clamp(12px, 2.08333rem, 44px); }

@media (min-width: 577px) {
  .fz-sm-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-sm-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-sm-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-sm-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-sm-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-sm-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-sm-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-sm-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-sm-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-sm-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-sm-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-sm-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-sm-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-sm-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-sm-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 769px) {
  .fz-md-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-md-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-md-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-md-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-md-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-md-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-md-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-md-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-md-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-md-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-md-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-md-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-md-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-md-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-md-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1025px) {
  .fz-lg-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-lg-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-lg-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-lg-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-lg-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-lg-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-lg-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-lg-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-lg-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-lg-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-lg-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-lg-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-lg-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-lg-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-lg-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1281px) {
  .fz-xl-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-xl-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-xl-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-xl-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-xl-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-xl-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-xl-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-xl-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-xl-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-xl-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-xl-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-xl-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-xl-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-xl-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-xl-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1441px) {
  .fz-2xl-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-2xl-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-2xl-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-2xl-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-2xl-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-2xl-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-2xl-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-2xl-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-2xl-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-2xl-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-2xl-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-2xl-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-2xl-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-2xl-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-2xl-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

@media (min-width: 1601px) {
  .fz-3xl-12 {
    font-size: clamp(12px, 0.625rem, 16px); }
  .fz-3xl-14 {
    font-size: clamp(12px, 0.72917rem, 18px); }
  .fz-3xl-16 {
    font-size: clamp(12px, 0.83333rem, 20px); }
  .fz-3xl-18 {
    font-size: clamp(12px, 0.9375rem, 22px); }
  .fz-3xl-20 {
    font-size: clamp(12px, 1.04167rem, 24px); }
  .fz-3xl-22 {
    font-size: clamp(12px, 1.14583rem, 26px); }
  .fz-3xl-24 {
    font-size: clamp(12px, 1.25rem, 28px); }
  .fz-3xl-26 {
    font-size: clamp(12px, 1.35417rem, 30px); }
  .fz-3xl-28 {
    font-size: clamp(12px, 1.45833rem, 32px); }
  .fz-3xl-30 {
    font-size: clamp(12px, 1.5625rem, 34px); }
  .fz-3xl-32 {
    font-size: clamp(12px, 1.66667rem, 36px); }
  .fz-3xl-34 {
    font-size: clamp(12px, 1.77083rem, 38px); }
  .fz-3xl-36 {
    font-size: clamp(12px, 1.875rem, 40px); }
  .fz-3xl-38 {
    font-size: clamp(12px, 1.97917rem, 42px); }
  .fz-3xl-40 {
    font-size: clamp(12px, 2.08333rem, 44px); } }

.form-group [type='color'], .form-group [type='date'], .form-group [type='datetime'], .form-group [type='datetime-local'], .form-group [type='email'], .form-group [type='month'], .form-group [type='number'], .form-group [type='password'], .form-group [type='search'], .form-group [type='tel'], .form-group [type='text'], .form-group [type='time'], .form-group [type='url'], .form-group [type='week'], .form-group input:not([type]), .form-group textarea {
  height: 2.08333rem;
  width: 100%;
  background: white;
  border: 1px solid #dddddd;
  padding: 0 1.25rem;
  color: #fff;
  font-size: 0.72917rem; }
  .form-group [type='color']::placeholder, .form-group [type='date']::placeholder, .form-group [type='datetime']::placeholder, .form-group [type='datetime-local']::placeholder, .form-group [type='email']::placeholder, .form-group [type='month']::placeholder, .form-group [type='number']::placeholder, .form-group [type='password']::placeholder, .form-group [type='search']::placeholder, .form-group [type='tel']::placeholder, .form-group [type='text']::placeholder, .form-group [type='time']::placeholder, .form-group [type='url']::placeholder, .form-group [type='week']::placeholder, .form-group input:not([type])::placeholder, .form-group textarea::placeholder {
    color: #555555;
    opacity: 1; }

.form-group select {
  height: 2.08333rem;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;
  padding: 0 1.25rem;
  color: #555555;
  font-size: 0.72917rem; }

.form-group textarea {
  height: 6.25rem;
  padding: 0.625rem 1.25rem; }

.frm-btnwrap {
  margin-top: 1.04167rem; }
  .frm-btnwrap .label {
    display: none; }

.frm-btn {
  position: relative;
  display: inline-block;
  margin-left: auto;
  min-width: 5.625rem; }
  .frm-btn:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.83333rem;
    content: '\f178';
    font-family: "Font Awesome 6 Pro";
    color: #fff;
    pointer-events: none;
    font-weight: 300;
    font-size: clamp(12px, 1.04167rem, 1.25rem); }
  .frm-btn input[type="submit"] {
    transition: all 0.3s ease-in-out;
    background-color: #b2151b;
    color: #fff;
    border-radius: 0.41667rem;
    border: 0;
    height: 2.29167rem;
    width: 100%;
    cursor: pointer;
    font-size: clamp(12px, 0.83333rem, 1.04167rem);
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 2.91667rem 0 1.25rem; }

.gap-0 {
  gap: 0rem; }

.gap-4 {
  gap: 0.20833rem; }

.gap-8 {
  gap: 0.41667rem; }

.gap-12 {
  gap: 0.625rem; }

.gap-16 {
  gap: 0.83333rem; }

.gap-20 {
  gap: 1.04167rem; }

.gap-24 {
  gap: 1.25rem; }

.gap-28 {
  gap: 1.45833rem; }

.gap-32 {
  gap: 1.66667rem; }

.gap-36 {
  gap: 1.875rem; }

@media (min-width: 577px) {
  .gap-sm-0 {
    gap: 0rem; }
  .gap-sm-4 {
    gap: 0.20833rem; }
  .gap-sm-8 {
    gap: 0.41667rem; }
  .gap-sm-12 {
    gap: 0.625rem; }
  .gap-sm-16 {
    gap: 0.83333rem; }
  .gap-sm-20 {
    gap: 1.04167rem; }
  .gap-sm-24 {
    gap: 1.25rem; }
  .gap-sm-28 {
    gap: 1.45833rem; }
  .gap-sm-32 {
    gap: 1.66667rem; }
  .gap-sm-36 {
    gap: 1.875rem; } }

@media (min-width: 769px) {
  .gap-md-0 {
    gap: 0rem; }
  .gap-md-4 {
    gap: 0.20833rem; }
  .gap-md-8 {
    gap: 0.41667rem; }
  .gap-md-12 {
    gap: 0.625rem; }
  .gap-md-16 {
    gap: 0.83333rem; }
  .gap-md-20 {
    gap: 1.04167rem; }
  .gap-md-24 {
    gap: 1.25rem; }
  .gap-md-28 {
    gap: 1.45833rem; }
  .gap-md-32 {
    gap: 1.66667rem; }
  .gap-md-36 {
    gap: 1.875rem; } }

@media (min-width: 1025px) {
  .gap-lg-0 {
    gap: 0rem; }
  .gap-lg-4 {
    gap: 0.20833rem; }
  .gap-lg-8 {
    gap: 0.41667rem; }
  .gap-lg-12 {
    gap: 0.625rem; }
  .gap-lg-16 {
    gap: 0.83333rem; }
  .gap-lg-20 {
    gap: 1.04167rem; }
  .gap-lg-24 {
    gap: 1.25rem; }
  .gap-lg-28 {
    gap: 1.45833rem; }
  .gap-lg-32 {
    gap: 1.66667rem; }
  .gap-lg-36 {
    gap: 1.875rem; } }

@media (min-width: 1281px) {
  .gap-xl-0 {
    gap: 0rem; }
  .gap-xl-4 {
    gap: 0.20833rem; }
  .gap-xl-8 {
    gap: 0.41667rem; }
  .gap-xl-12 {
    gap: 0.625rem; }
  .gap-xl-16 {
    gap: 0.83333rem; }
  .gap-xl-20 {
    gap: 1.04167rem; }
  .gap-xl-24 {
    gap: 1.25rem; }
  .gap-xl-28 {
    gap: 1.45833rem; }
  .gap-xl-32 {
    gap: 1.66667rem; }
  .gap-xl-36 {
    gap: 1.875rem; } }

@media (min-width: 1441px) {
  .gap-2xl-0 {
    gap: 0rem; }
  .gap-2xl-4 {
    gap: 0.20833rem; }
  .gap-2xl-8 {
    gap: 0.41667rem; }
  .gap-2xl-12 {
    gap: 0.625rem; }
  .gap-2xl-16 {
    gap: 0.83333rem; }
  .gap-2xl-20 {
    gap: 1.04167rem; }
  .gap-2xl-24 {
    gap: 1.25rem; }
  .gap-2xl-28 {
    gap: 1.45833rem; }
  .gap-2xl-32 {
    gap: 1.66667rem; }
  .gap-2xl-36 {
    gap: 1.875rem; } }

@media (min-width: 1601px) {
  .gap-3xl-0 {
    gap: 0rem; }
  .gap-3xl-4 {
    gap: 0.20833rem; }
  .gap-3xl-8 {
    gap: 0.41667rem; }
  .gap-3xl-12 {
    gap: 0.625rem; }
  .gap-3xl-16 {
    gap: 0.83333rem; }
  .gap-3xl-20 {
    gap: 1.04167rem; }
  .gap-3xl-24 {
    gap: 1.25rem; }
  .gap-3xl-28 {
    gap: 1.45833rem; }
  .gap-3xl-32 {
    gap: 1.66667rem; }
  .gap-3xl-36 {
    gap: 1.875rem; } }

.img-zoom-out {
  overflow: hidden; }
  .img-zoom-out img {
    transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .img-zoom-out:hover img {
      transform: scale(1.05); } }

.img-zoom-in {
  overflow: hidden; }
  .img-zoom-in img {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05); }
  @media (hover: hover) and (pointer: fine) {
    .img-zoom-in:hover img {
      transform: scale(1); } }

@media (hover: hover) and (pointer: fine) {
  .link-hover:hover, .footer-bottom .footer-link ul li a:hover {
    text-decoration: underline; } }

.ratio-1-1 {
  position: relative;
  padding-top: 100%; }
  .ratio-1-1 img, .ratio-1-1 iframe, .ratio-1-1 video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.ratio-16-9 {
  position: relative;
  padding-top: 56.25%; }
  .ratio-16-9 img, .ratio-16-9 iframe, .ratio-16-9 video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.ratio-16-10 {
  position: relative;
  padding-top: 62.5%; }
  .ratio-16-10 img, .ratio-16-10 iframe, .ratio-16-10 video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.ratio-4-3 {
  position: relative;
  padding-top: 75%; }
  .ratio-4-3 img, .ratio-4-3 iframe, .ratio-4-3 video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.object-cover img {
  height: 100%;
  width: 100%;
  object-fit: cover; }

.object-contain img {
  height: 100%;
  width: 100%;
  object-fit: contain; }

[class*="ratio-"] {
  display: block; }
  [class*="ratio-"].is-contain img {
    object-fit: contain; }

.layout > * {
  width: 100%; }

.layout.is-gap-0 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-0 > * + * {
    margin-top: 0.05208rem; }

.layout.is-gap-4 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-4 > * + * {
    margin-top: 0.26042rem; }

.layout.is-gap-8 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-8 > * + * {
    margin-top: 0.46875rem; }

.layout.is-gap-12 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-12 > * + * {
    margin-top: 0.67708rem; }

.layout.is-gap-16 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-16 > * + * {
    margin-top: 0.88542rem; }

.layout.is-gap-20 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-20 > * + * {
    margin-top: 1.09375rem; }

.layout.is-gap-24 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-24 > * + * {
    margin-top: 1.30208rem; }

.layout.is-gap-28 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-28 > * + * {
    margin-top: 1.51042rem; }

.layout.is-gap-32 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-32 > * + * {
    margin-top: 1.71875rem; }

.layout.is-gap-36 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-36 > * + * {
    margin-top: 1.92708rem; }

.layout.is-gap-40 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-40 > * + * {
    margin-top: 2.13542rem; }

.layout.is-gap-44 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-44 > * + * {
    margin-top: 2.34375rem; }

.layout.is-gap-48 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-48 > * + * {
    margin-top: 2.55208rem; }

.layout.is-gap-52 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-52 > * + * {
    margin-top: 2.76042rem; }

.layout.is-gap-56 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-56 > * + * {
    margin-top: 2.96875rem; }

.layout.is-gap-60 {
  display: flex;
  flex-direction: column; }
  .layout.is-gap-60 > * + * {
    margin-top: 3.17708rem; }

@media (min-width: 577px) {
  .layout.is-gap-sm-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-sm-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-sm-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-sm-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-sm-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-sm-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-sm-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-sm-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-sm-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-sm-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-sm-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-sm-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-sm-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-sm-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-sm-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-sm-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-sm-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 769px) {
  .layout.is-gap-md-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-md-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-md-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-md-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-md-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-md-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-md-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-md-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-md-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-md-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-md-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-md-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-md-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-md-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-md-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-md-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-md-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1025px) {
  .layout.is-gap-lg-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-lg-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-lg-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-lg-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-lg-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-lg-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-lg-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-lg-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-lg-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-lg-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-lg-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-lg-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-lg-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-lg-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-lg-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-lg-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-lg-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1281px) {
  .layout.is-gap-xl-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-xl-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-xl-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-xl-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-xl-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-xl-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-xl-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-xl-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-xl-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-xl-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-xl-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-xl-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-xl-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-xl-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-xl-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-xl-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-xl-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1441px) {
  .layout.is-gap-2xl-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-2xl-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-2xl-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-2xl-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-2xl-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-2xl-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-2xl-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-2xl-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-2xl-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-2xl-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-2xl-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-2xl-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-2xl-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-2xl-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-2xl-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-2xl-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-2xl-60 > * + * {
      margin-top: 3.125rem; } }

@media (min-width: 1601px) {
  .layout.is-gap-3xl-0 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-0 > * + * {
      margin-top: 0rem; }
  .layout.is-gap-3xl-4 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-4 > * + * {
      margin-top: 0.20833rem; }
  .layout.is-gap-3xl-8 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-8 > * + * {
      margin-top: 0.41667rem; }
  .layout.is-gap-3xl-12 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-12 > * + * {
      margin-top: 0.625rem; }
  .layout.is-gap-3xl-16 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-16 > * + * {
      margin-top: 0.83333rem; }
  .layout.is-gap-3xl-20 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-20 > * + * {
      margin-top: 1.04167rem; }
  .layout.is-gap-3xl-24 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-24 > * + * {
      margin-top: 1.25rem; }
  .layout.is-gap-3xl-28 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-28 > * + * {
      margin-top: 1.45833rem; }
  .layout.is-gap-3xl-32 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-32 > * + * {
      margin-top: 1.66667rem; }
  .layout.is-gap-3xl-36 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-36 > * + * {
      margin-top: 1.875rem; }
  .layout.is-gap-3xl-40 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-40 > * + * {
      margin-top: 2.08333rem; }
  .layout.is-gap-3xl-44 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-44 > * + * {
      margin-top: 2.29167rem; }
  .layout.is-gap-3xl-48 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-48 > * + * {
      margin-top: 2.5rem; }
  .layout.is-gap-3xl-52 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-52 > * + * {
      margin-top: 2.70833rem; }
  .layout.is-gap-3xl-56 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-56 > * + * {
      margin-top: 2.91667rem; }
  .layout.is-gap-3xl-60 {
    display: flex;
    flex-direction: column; }
    .layout.is-gap-3xl-60 > * + * {
      margin-top: 3.125rem; } }

.text-primary-50 {
  color: #fee9ea; }

.text-primary-100 {
  color: #fbd2d3; }

.text-primary-200 {
  color: #f8a4a7; }

.text-primary-300 {
  color: #f4777c; }

.text-primary-400 {
  color: #f14950; }

.text-primary-500 {
  color: #ed1c24; }

.text-primary-600 {
  color: #b2151b; }

.text-primary-700 {
  color: #770e12; }

.text-primary-800 {
  color: #3b0709; }

.text-primary-900 {
  color: #180304; }

.text-secondary-50 {
  color: #e6f7ee; }

.text-secondary-100 {
  color: #cceddc; }

.text-secondary-200 {
  color: #99dbb9; }

.text-secondary-300 {
  color: #66ca97; }

.text-secondary-400 {
  color: #33b874; }

.text-secondary-500 {
  color: #00a651; }

.text-secondary-600 {
  color: #007d3d; }

.text-secondary-700 {
  color: #005329; }

.text-secondary-800 {
  color: #002a14; }

.text-secondary-900 {
  color: #001108; }

.text-gray-50 {
  color: #f5f5f5; }

.text-gray-100 {
  color: #dddddd; }

.text-gray-200 {
  color: #bbbbbb; }

.text-gray-300 {
  color: #999999; }

.text-gray-400 {
  color: #777777; }

.text-gray-500 {
  color: #555555; }

.text-gray-600 {
  color: #404040; }

.text-gray-700 {
  color: #2b2b2b; }

.text-gray-800 {
  color: #151515; }

.text-gray-900 {
  color: #090909; }

.text-white {
  color: #fff; }

.background-primary-50 {
  background: #fee9ea; }

.background-primary-100 {
  background: #fbd2d3; }

.background-primary-200 {
  background: #f8a4a7; }

.background-primary-300 {
  background: #f4777c; }

.background-primary-400 {
  background: #f14950; }

.background-primary-500 {
  background: #ed1c24; }

.background-primary-600 {
  background: #b2151b; }

.background-primary-700 {
  background: #770e12; }

.background-primary-800 {
  background: #3b0709; }

.background-primary-900 {
  background: #180304; }

.background-secondary-50 {
  background: #e6f7ee; }

.background-secondary-100 {
  background: #cceddc; }

.background-secondary-200 {
  background: #99dbb9; }

.background-secondary-300 {
  background: #66ca97; }

.background-secondary-400 {
  background: #33b874; }

.background-secondary-500 {
  background: #00a651; }

.background-secondary-600 {
  background: #007d3d; }

.background-secondary-700 {
  background: #005329; }

.background-secondary-800 {
  background: #002a14; }

.background-secondary-900 {
  background: #001108; }

.background-gray-50 {
  background: #f5f5f5; }

.background-gray-100 {
  background: #dddddd; }

.background-gray-200 {
  background: #bbbbbb; }

.background-gray-300 {
  background: #999999; }

.background-gray-400 {
  background: #777777; }

.background-gray-500 {
  background: #555555; }

.background-gray-600 {
  background: #404040; }

.background-gray-700 {
  background: #2b2b2b; }

.background-gray-800 {
  background: #151515; }

.background-gray-900 {
  background: #090909; }

.background-white {
  background: #fff; }

.background-gradient-linear-1 {
  background-image: linear-gradient(to bottom, #33b874 0%, #e6cf00 50%, #e6cf00 100%); }

.background-gradient-linear-2 {
  background-image: linear-gradient(90deg, #b21516 0%, #d71b22 37.5%, #870f15 100%); }

.background-gradient-linear-3 {
  background-image: linear-gradient(to bottom, #dfcd2c 0%, #e0c42f 100%); }

.background-gradient-linear-4 {
  background-image: linear-gradient(180deg, rgba(223, 205, 44, 0.08) 0%, rgba(224, 196, 47, 0.68) 100%); }

.background-gradient-linear-5 {
  background-image: linear-gradient(180deg, #b21516 0%, #d71b22 37.5%, #870f15 100%); }

.background-gradient-linear-6 {
  background-image: linear-gradient(180deg, #FDF3EC 0%, #FAF4C4 50%, #D8F5E6 100%); }

.box-shadow-1 {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.04); }

/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background gradient -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Content margins -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Responsive Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
.pages, .modulepager {
  margin-top: 1.66667rem; }
  .pages .pagination, .modulepager .pagination {
    display: flex;
    align-items: center;
    justify-content: center; }
    .pages .pagination li, .modulepager .pagination li {
      list-style: none;
      display: inline-block;
      text-align: center;
      margin-left: 0.41667rem; }
      .pages .pagination li:first-child, .modulepager .pagination li:first-child {
        margin-left: 0; }
      .pages .pagination li a, .pages .pagination li span, .modulepager .pagination li a, .modulepager .pagination li span {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        width: 2.1875rem;
        height: 2.1875rem;
        font-size: 1.04167rem;
        border: 1px solid #ed1c24;
        font-weight: 700;
        color: #ed1c24;
        border-radius: 50%;
        background-clip: padding-box;
        transition: all 0.3s ease-in-out; }
      .pages .pagination li.active a, .pages .pagination li.active span, .pages .pagination li:hover a, .pages .pagination li:hover span, .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
        background-color: #ed1c24;
        color: #fff; }

.no-pagination .pages {
  display: none; }

.radius-0 {
  border-radius: 0rem; }

.radius-4 {
  border-radius: 0.20833rem; }

.radius-8 {
  border-radius: 0.41667rem; }

.radius-12 {
  border-radius: 0.625rem; }

.radius-16 {
  border-radius: 0.83333rem; }

.radius-20 {
  border-radius: 1.04167rem; }

.radius-24 {
  border-radius: 1.25rem; }

.radius-28 {
  border-radius: 1.45833rem; }

.radius-32 {
  border-radius: 1.66667rem; }

.radius-36 {
  border-radius: 1.875rem; }

@media (min-width: 577px) {
  .radius-sm-0 {
    border-radius: 0rem; }
  .radius-sm-4 {
    border-radius: 0.20833rem; }
  .radius-sm-8 {
    border-radius: 0.41667rem; }
  .radius-sm-12 {
    border-radius: 0.625rem; }
  .radius-sm-16 {
    border-radius: 0.83333rem; }
  .radius-sm-20 {
    border-radius: 1.04167rem; }
  .radius-sm-24 {
    border-radius: 1.25rem; }
  .radius-sm-28 {
    border-radius: 1.45833rem; }
  .radius-sm-32 {
    border-radius: 1.66667rem; }
  .radius-sm-36 {
    border-radius: 1.875rem; } }

@media (min-width: 769px) {
  .radius-md-0 {
    border-radius: 0rem; }
  .radius-md-4 {
    border-radius: 0.20833rem; }
  .radius-md-8 {
    border-radius: 0.41667rem; }
  .radius-md-12 {
    border-radius: 0.625rem; }
  .radius-md-16 {
    border-radius: 0.83333rem; }
  .radius-md-20 {
    border-radius: 1.04167rem; }
  .radius-md-24 {
    border-radius: 1.25rem; }
  .radius-md-28 {
    border-radius: 1.45833rem; }
  .radius-md-32 {
    border-radius: 1.66667rem; }
  .radius-md-36 {
    border-radius: 1.875rem; } }

@media (min-width: 1025px) {
  .radius-lg-0 {
    border-radius: 0rem; }
  .radius-lg-4 {
    border-radius: 0.20833rem; }
  .radius-lg-8 {
    border-radius: 0.41667rem; }
  .radius-lg-12 {
    border-radius: 0.625rem; }
  .radius-lg-16 {
    border-radius: 0.83333rem; }
  .radius-lg-20 {
    border-radius: 1.04167rem; }
  .radius-lg-24 {
    border-radius: 1.25rem; }
  .radius-lg-28 {
    border-radius: 1.45833rem; }
  .radius-lg-32 {
    border-radius: 1.66667rem; }
  .radius-lg-36 {
    border-radius: 1.875rem; } }

@media (min-width: 1281px) {
  .radius-xl-0 {
    border-radius: 0rem; }
  .radius-xl-4 {
    border-radius: 0.20833rem; }
  .radius-xl-8 {
    border-radius: 0.41667rem; }
  .radius-xl-12 {
    border-radius: 0.625rem; }
  .radius-xl-16 {
    border-radius: 0.83333rem; }
  .radius-xl-20 {
    border-radius: 1.04167rem; }
  .radius-xl-24 {
    border-radius: 1.25rem; }
  .radius-xl-28 {
    border-radius: 1.45833rem; }
  .radius-xl-32 {
    border-radius: 1.66667rem; }
  .radius-xl-36 {
    border-radius: 1.875rem; } }

@media (min-width: 1441px) {
  .radius-2xl-0 {
    border-radius: 0rem; }
  .radius-2xl-4 {
    border-radius: 0.20833rem; }
  .radius-2xl-8 {
    border-radius: 0.41667rem; }
  .radius-2xl-12 {
    border-radius: 0.625rem; }
  .radius-2xl-16 {
    border-radius: 0.83333rem; }
  .radius-2xl-20 {
    border-radius: 1.04167rem; }
  .radius-2xl-24 {
    border-radius: 1.25rem; }
  .radius-2xl-28 {
    border-radius: 1.45833rem; }
  .radius-2xl-32 {
    border-radius: 1.66667rem; }
  .radius-2xl-36 {
    border-radius: 1.875rem; } }

@media (min-width: 1601px) {
  .radius-3xl-0 {
    border-radius: 0rem; }
  .radius-3xl-4 {
    border-radius: 0.20833rem; }
  .radius-3xl-8 {
    border-radius: 0.41667rem; }
  .radius-3xl-12 {
    border-radius: 0.625rem; }
  .radius-3xl-16 {
    border-radius: 0.83333rem; }
  .radius-3xl-20 {
    border-radius: 1.04167rem; }
  .radius-3xl-24 {
    border-radius: 1.25rem; }
  .radius-3xl-28 {
    border-radius: 1.45833rem; }
  .radius-3xl-32 {
    border-radius: 1.66667rem; }
  .radius-3xl-36 {
    border-radius: 1.875rem; } }

.section-large {
  padding: 4.16667rem 0; }
  @media (max-width: 1024px) {
    .section-large {
      padding: 2.08333rem 0; } }

.section-medium {
  padding: 3.75rem 0; }
  @media (max-width: 1024px) {
    .section-medium {
      padding: 2.08333rem 0; } }

.section {
  padding: 3.125rem 0; }
  @media (max-width: 1024px) {
    .section {
      padding: 2.08333rem 0; } }

.section-small {
  padding: 2.5rem 0; }
  @media (max-width: 1024px) {
    .section-small {
      padding: 2.08333rem 0; } }

section.pb-0 {
  padding-bottom: 0 !important; }

section.pt-0 {
  padding-top: 0 !important; }

.mt-0 {
  margin-top: 0rem;
  : 0rem; }

.mt-1 {
  margin-top: 0.20833rem;
  : 0.20833rem; }

.mt-2 {
  margin-top: 0.41667rem;
  : 0.41667rem; }

.mt-3 {
  margin-top: 0.625rem;
  : 0.625rem; }

.mt-4 {
  margin-top: 0.83333rem;
  : 0.83333rem; }

.mt-5 {
  margin-top: 1.04167rem;
  : 1.04167rem; }

.mt-6 {
  margin-top: 1.25rem;
  : 1.25rem; }

.mt-7 {
  margin-top: 1.45833rem;
  : 1.45833rem; }

.mt-8 {
  margin-top: 1.66667rem;
  : 1.66667rem; }

.mt-9 {
  margin-top: 1.875rem;
  : 1.875rem; }

.mt-10 {
  margin-top: 2.08333rem;
  : 2.08333rem; }

.mt-11 {
  margin-top: 2.29167rem;
  : 2.29167rem; }

.mt-12 {
  margin-top: 2.5rem;
  : 2.5rem; }

.mt-13 {
  margin-top: 2.70833rem;
  : 2.70833rem; }

.mt-14 {
  margin-top: 2.91667rem;
  : 2.91667rem; }

.mt-15 {
  margin-top: 3.125rem;
  : 3.125rem; }

.mt-16 {
  margin-top: 3.33333rem;
  : 3.33333rem; }

.mr-0 {
  margin-right: 0rem;
  : 0rem; }

.mr-1 {
  margin-right: 0.20833rem;
  : 0.20833rem; }

.mr-2 {
  margin-right: 0.41667rem;
  : 0.41667rem; }

.mr-3 {
  margin-right: 0.625rem;
  : 0.625rem; }

.mr-4 {
  margin-right: 0.83333rem;
  : 0.83333rem; }

.mr-5 {
  margin-right: 1.04167rem;
  : 1.04167rem; }

.mr-6 {
  margin-right: 1.25rem;
  : 1.25rem; }

.mr-7 {
  margin-right: 1.45833rem;
  : 1.45833rem; }

.mr-8 {
  margin-right: 1.66667rem;
  : 1.66667rem; }

.mr-9 {
  margin-right: 1.875rem;
  : 1.875rem; }

.mr-10 {
  margin-right: 2.08333rem;
  : 2.08333rem; }

.mr-11 {
  margin-right: 2.29167rem;
  : 2.29167rem; }

.mr-12 {
  margin-right: 2.5rem;
  : 2.5rem; }

.mr-13 {
  margin-right: 2.70833rem;
  : 2.70833rem; }

.mr-14 {
  margin-right: 2.91667rem;
  : 2.91667rem; }

.mr-15 {
  margin-right: 3.125rem;
  : 3.125rem; }

.mr-16 {
  margin-right: 3.33333rem;
  : 3.33333rem; }

.mb-0 {
  margin-bottom: 0rem;
  : 0rem; }

.mb-1 {
  margin-bottom: 0.20833rem;
  : 0.20833rem; }

.mb-2 {
  margin-bottom: 0.41667rem;
  : 0.41667rem; }

.mb-3 {
  margin-bottom: 0.625rem;
  : 0.625rem; }

.mb-4 {
  margin-bottom: 0.83333rem;
  : 0.83333rem; }

.mb-5 {
  margin-bottom: 1.04167rem;
  : 1.04167rem; }

.mb-6 {
  margin-bottom: 1.25rem;
  : 1.25rem; }

.mb-7 {
  margin-bottom: 1.45833rem;
  : 1.45833rem; }

.mb-8 {
  margin-bottom: 1.66667rem;
  : 1.66667rem; }

.mb-9 {
  margin-bottom: 1.875rem;
  : 1.875rem; }

.mb-10 {
  margin-bottom: 2.08333rem;
  : 2.08333rem; }

.mb-11 {
  margin-bottom: 2.29167rem;
  : 2.29167rem; }

.mb-12 {
  margin-bottom: 2.5rem;
  : 2.5rem; }

.mb-13 {
  margin-bottom: 2.70833rem;
  : 2.70833rem; }

.mb-14 {
  margin-bottom: 2.91667rem;
  : 2.91667rem; }

.mb-15 {
  margin-bottom: 3.125rem;
  : 3.125rem; }

.mb-16 {
  margin-bottom: 3.33333rem;
  : 3.33333rem; }

.ml-0 {
  margin-left: 0rem;
  : 0rem; }

.ml-1 {
  margin-left: 0.20833rem;
  : 0.20833rem; }

.ml-2 {
  margin-left: 0.41667rem;
  : 0.41667rem; }

.ml-3 {
  margin-left: 0.625rem;
  : 0.625rem; }

.ml-4 {
  margin-left: 0.83333rem;
  : 0.83333rem; }

.ml-5 {
  margin-left: 1.04167rem;
  : 1.04167rem; }

.ml-6 {
  margin-left: 1.25rem;
  : 1.25rem; }

.ml-7 {
  margin-left: 1.45833rem;
  : 1.45833rem; }

.ml-8 {
  margin-left: 1.66667rem;
  : 1.66667rem; }

.ml-9 {
  margin-left: 1.875rem;
  : 1.875rem; }

.ml-10 {
  margin-left: 2.08333rem;
  : 2.08333rem; }

.ml-11 {
  margin-left: 2.29167rem;
  : 2.29167rem; }

.ml-12 {
  margin-left: 2.5rem;
  : 2.5rem; }

.ml-13 {
  margin-left: 2.70833rem;
  : 2.70833rem; }

.ml-14 {
  margin-left: 2.91667rem;
  : 2.91667rem; }

.ml-15 {
  margin-left: 3.125rem;
  : 3.125rem; }

.ml-16 {
  margin-left: 3.33333rem;
  : 3.33333rem; }

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mx-1 {
  margin-left: 0.20833rem;
  margin-right: 0.20833rem; }

.mx-2 {
  margin-left: 0.41667rem;
  margin-right: 0.41667rem; }

.mx-3 {
  margin-left: 0.625rem;
  margin-right: 0.625rem; }

.mx-4 {
  margin-left: 0.83333rem;
  margin-right: 0.83333rem; }

.mx-5 {
  margin-left: 1.04167rem;
  margin-right: 1.04167rem; }

.mx-6 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mx-7 {
  margin-left: 1.45833rem;
  margin-right: 1.45833rem; }

.mx-8 {
  margin-left: 1.66667rem;
  margin-right: 1.66667rem; }

.mx-9 {
  margin-left: 1.875rem;
  margin-right: 1.875rem; }

.mx-10 {
  margin-left: 2.08333rem;
  margin-right: 2.08333rem; }

.mx-11 {
  margin-left: 2.29167rem;
  margin-right: 2.29167rem; }

.mx-12 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mx-13 {
  margin-left: 2.70833rem;
  margin-right: 2.70833rem; }

.mx-14 {
  margin-left: 2.91667rem;
  margin-right: 2.91667rem; }

.mx-15 {
  margin-left: 3.125rem;
  margin-right: 3.125rem; }

.mx-16 {
  margin-left: 3.33333rem;
  margin-right: 3.33333rem; }

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.my-1 {
  margin-top: 0.20833rem;
  margin-bottom: 0.20833rem; }

.my-2 {
  margin-top: 0.41667rem;
  margin-bottom: 0.41667rem; }

.my-3 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem; }

.my-4 {
  margin-top: 0.83333rem;
  margin-bottom: 0.83333rem; }

.my-5 {
  margin-top: 1.04167rem;
  margin-bottom: 1.04167rem; }

.my-6 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.my-7 {
  margin-top: 1.45833rem;
  margin-bottom: 1.45833rem; }

.my-8 {
  margin-top: 1.66667rem;
  margin-bottom: 1.66667rem; }

.my-9 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }

.my-10 {
  margin-top: 2.08333rem;
  margin-bottom: 2.08333rem; }

.my-11 {
  margin-top: 2.29167rem;
  margin-bottom: 2.29167rem; }

.my-12 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.my-13 {
  margin-top: 2.70833rem;
  margin-bottom: 2.70833rem; }

.my-14 {
  margin-top: 2.91667rem;
  margin-bottom: 2.91667rem; }

.my-15 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.my-16 {
  margin-top: 3.33333rem;
  margin-bottom: 3.33333rem; }

.m-0 {
  margin: 0rem;
  : 0rem; }

.m-1 {
  margin: 0.20833rem;
  : 0.20833rem; }

.m-2 {
  margin: 0.41667rem;
  : 0.41667rem; }

.m-3 {
  margin: 0.625rem;
  : 0.625rem; }

.m-4 {
  margin: 0.83333rem;
  : 0.83333rem; }

.m-5 {
  margin: 1.04167rem;
  : 1.04167rem; }

.m-6 {
  margin: 1.25rem;
  : 1.25rem; }

.m-7 {
  margin: 1.45833rem;
  : 1.45833rem; }

.m-8 {
  margin: 1.66667rem;
  : 1.66667rem; }

.m-9 {
  margin: 1.875rem;
  : 1.875rem; }

.m-10 {
  margin: 2.08333rem;
  : 2.08333rem; }

.m-11 {
  margin: 2.29167rem;
  : 2.29167rem; }

.m-12 {
  margin: 2.5rem;
  : 2.5rem; }

.m-13 {
  margin: 2.70833rem;
  : 2.70833rem; }

.m-14 {
  margin: 2.91667rem;
  : 2.91667rem; }

.m-15 {
  margin: 3.125rem;
  : 3.125rem; }

.m-16 {
  margin: 3.33333rem;
  : 3.33333rem; }

.pt-0 {
  padding-top: 0rem;
  : 0rem; }

.pt-1 {
  padding-top: 0.20833rem;
  : 0.20833rem; }

.pt-2 {
  padding-top: 0.41667rem;
  : 0.41667rem; }

.pt-3 {
  padding-top: 0.625rem;
  : 0.625rem; }

.pt-4 {
  padding-top: 0.83333rem;
  : 0.83333rem; }

.pt-5 {
  padding-top: 1.04167rem;
  : 1.04167rem; }

.pt-6 {
  padding-top: 1.25rem;
  : 1.25rem; }

.pt-7 {
  padding-top: 1.45833rem;
  : 1.45833rem; }

.pt-8 {
  padding-top: 1.66667rem;
  : 1.66667rem; }

.pt-9 {
  padding-top: 1.875rem;
  : 1.875rem; }

.pt-10 {
  padding-top: 2.08333rem;
  : 2.08333rem; }

.pt-11 {
  padding-top: 2.29167rem;
  : 2.29167rem; }

.pt-12 {
  padding-top: 2.5rem;
  : 2.5rem; }

.pt-13 {
  padding-top: 2.70833rem;
  : 2.70833rem; }

.pt-14 {
  padding-top: 2.91667rem;
  : 2.91667rem; }

.pt-15 {
  padding-top: 3.125rem;
  : 3.125rem; }

.pt-16 {
  padding-top: 3.33333rem;
  : 3.33333rem; }

.pr-0 {
  padding-right: 0rem;
  : 0rem; }

.pr-1 {
  padding-right: 0.20833rem;
  : 0.20833rem; }

.pr-2 {
  padding-right: 0.41667rem;
  : 0.41667rem; }

.pr-3 {
  padding-right: 0.625rem;
  : 0.625rem; }

.pr-4 {
  padding-right: 0.83333rem;
  : 0.83333rem; }

.pr-5 {
  padding-right: 1.04167rem;
  : 1.04167rem; }

.pr-6 {
  padding-right: 1.25rem;
  : 1.25rem; }

.pr-7 {
  padding-right: 1.45833rem;
  : 1.45833rem; }

.pr-8 {
  padding-right: 1.66667rem;
  : 1.66667rem; }

.pr-9 {
  padding-right: 1.875rem;
  : 1.875rem; }

.pr-10 {
  padding-right: 2.08333rem;
  : 2.08333rem; }

.pr-11 {
  padding-right: 2.29167rem;
  : 2.29167rem; }

.pr-12 {
  padding-right: 2.5rem;
  : 2.5rem; }

.pr-13 {
  padding-right: 2.70833rem;
  : 2.70833rem; }

.pr-14 {
  padding-right: 2.91667rem;
  : 2.91667rem; }

.pr-15 {
  padding-right: 3.125rem;
  : 3.125rem; }

.pr-16 {
  padding-right: 3.33333rem;
  : 3.33333rem; }

.pb-0 {
  padding-bottom: 0rem;
  : 0rem; }

.pb-1 {
  padding-bottom: 0.20833rem;
  : 0.20833rem; }

.pb-2 {
  padding-bottom: 0.41667rem;
  : 0.41667rem; }

.pb-3 {
  padding-bottom: 0.625rem;
  : 0.625rem; }

.pb-4 {
  padding-bottom: 0.83333rem;
  : 0.83333rem; }

.pb-5 {
  padding-bottom: 1.04167rem;
  : 1.04167rem; }

.pb-6 {
  padding-bottom: 1.25rem;
  : 1.25rem; }

.pb-7 {
  padding-bottom: 1.45833rem;
  : 1.45833rem; }

.pb-8 {
  padding-bottom: 1.66667rem;
  : 1.66667rem; }

.pb-9 {
  padding-bottom: 1.875rem;
  : 1.875rem; }

.pb-10 {
  padding-bottom: 2.08333rem;
  : 2.08333rem; }

.pb-11 {
  padding-bottom: 2.29167rem;
  : 2.29167rem; }

.pb-12 {
  padding-bottom: 2.5rem;
  : 2.5rem; }

.pb-13 {
  padding-bottom: 2.70833rem;
  : 2.70833rem; }

.pb-14 {
  padding-bottom: 2.91667rem;
  : 2.91667rem; }

.pb-15 {
  padding-bottom: 3.125rem;
  : 3.125rem; }

.pb-16 {
  padding-bottom: 3.33333rem;
  : 3.33333rem; }

.pl-0 {
  padding-left: 0rem;
  : 0rem; }

.pl-1 {
  padding-left: 0.20833rem;
  : 0.20833rem; }

.pl-2 {
  padding-left: 0.41667rem;
  : 0.41667rem; }

.pl-3 {
  padding-left: 0.625rem;
  : 0.625rem; }

.pl-4 {
  padding-left: 0.83333rem;
  : 0.83333rem; }

.pl-5 {
  padding-left: 1.04167rem;
  : 1.04167rem; }

.pl-6 {
  padding-left: 1.25rem;
  : 1.25rem; }

.pl-7 {
  padding-left: 1.45833rem;
  : 1.45833rem; }

.pl-8 {
  padding-left: 1.66667rem;
  : 1.66667rem; }

.pl-9 {
  padding-left: 1.875rem;
  : 1.875rem; }

.pl-10 {
  padding-left: 2.08333rem;
  : 2.08333rem; }

.pl-11 {
  padding-left: 2.29167rem;
  : 2.29167rem; }

.pl-12 {
  padding-left: 2.5rem;
  : 2.5rem; }

.pl-13 {
  padding-left: 2.70833rem;
  : 2.70833rem; }

.pl-14 {
  padding-left: 2.91667rem;
  : 2.91667rem; }

.pl-15 {
  padding-left: 3.125rem;
  : 3.125rem; }

.pl-16 {
  padding-left: 3.33333rem;
  : 3.33333rem; }

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.px-1 {
  padding-left: 0.20833rem;
  padding-right: 0.20833rem; }

.px-2 {
  padding-left: 0.41667rem;
  padding-right: 0.41667rem; }

.px-3 {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.px-4 {
  padding-left: 0.83333rem;
  padding-right: 0.83333rem; }

.px-5 {
  padding-left: 1.04167rem;
  padding-right: 1.04167rem; }

.px-6 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.px-7 {
  padding-left: 1.45833rem;
  padding-right: 1.45833rem; }

.px-8 {
  padding-left: 1.66667rem;
  padding-right: 1.66667rem; }

.px-9 {
  padding-left: 1.875rem;
  padding-right: 1.875rem; }

.px-10 {
  padding-left: 2.08333rem;
  padding-right: 2.08333rem; }

.px-11 {
  padding-left: 2.29167rem;
  padding-right: 2.29167rem; }

.px-12 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.px-13 {
  padding-left: 2.70833rem;
  padding-right: 2.70833rem; }

.px-14 {
  padding-left: 2.91667rem;
  padding-right: 2.91667rem; }

.px-15 {
  padding-left: 3.125rem;
  padding-right: 3.125rem; }

.px-16 {
  padding-left: 3.33333rem;
  padding-right: 3.33333rem; }

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.py-1 {
  padding-top: 0.20833rem;
  padding-bottom: 0.20833rem; }

.py-2 {
  padding-top: 0.41667rem;
  padding-bottom: 0.41667rem; }

.py-3 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.py-4 {
  padding-top: 0.83333rem;
  padding-bottom: 0.83333rem; }

.py-5 {
  padding-top: 1.04167rem;
  padding-bottom: 1.04167rem; }

.py-6 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.py-7 {
  padding-top: 1.45833rem;
  padding-bottom: 1.45833rem; }

.py-8 {
  padding-top: 1.66667rem;
  padding-bottom: 1.66667rem; }

.py-9 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.py-10 {
  padding-top: 2.08333rem;
  padding-bottom: 2.08333rem; }

.py-11 {
  padding-top: 2.29167rem;
  padding-bottom: 2.29167rem; }

.py-12 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.py-13 {
  padding-top: 2.70833rem;
  padding-bottom: 2.70833rem; }

.py-14 {
  padding-top: 2.91667rem;
  padding-bottom: 2.91667rem; }

.py-15 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }

.py-16 {
  padding-top: 3.33333rem;
  padding-bottom: 3.33333rem; }

.p-0 {
  padding: 0rem;
  : 0rem; }

.p-1 {
  padding: 0.20833rem;
  : 0.20833rem; }

.p-2 {
  padding: 0.41667rem;
  : 0.41667rem; }

.p-3 {
  padding: 0.625rem;
  : 0.625rem; }

.p-4 {
  padding: 0.83333rem;
  : 0.83333rem; }

.p-5 {
  padding: 1.04167rem;
  : 1.04167rem; }

.p-6 {
  padding: 1.25rem;
  : 1.25rem; }

.p-7 {
  padding: 1.45833rem;
  : 1.45833rem; }

.p-8 {
  padding: 1.66667rem;
  : 1.66667rem; }

.p-9 {
  padding: 1.875rem;
  : 1.875rem; }

.p-10 {
  padding: 2.08333rem;
  : 2.08333rem; }

.p-11 {
  padding: 2.29167rem;
  : 2.29167rem; }

.p-12 {
  padding: 2.5rem;
  : 2.5rem; }

.p-13 {
  padding: 2.70833rem;
  : 2.70833rem; }

.p-14 {
  padding: 2.91667rem;
  : 2.91667rem; }

.p-15 {
  padding: 3.125rem;
  : 3.125rem; }

.p-16 {
  padding: 3.33333rem;
  : 3.33333rem; }

@media (min-width: 577px) {
  .pt-sm-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-sm-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-sm-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-sm-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-sm-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-sm-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-sm-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-sm-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-sm-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-sm-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-sm-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-sm-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-sm-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-sm-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-sm-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-sm-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-sm-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pr-sm-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-sm-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-sm-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-sm-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-sm-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-sm-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-sm-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-sm-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-sm-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-sm-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-sm-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-sm-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-sm-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-sm-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-sm-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-sm-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-sm-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pb-sm-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-sm-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-sm-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-sm-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-sm-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-sm-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-sm-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-sm-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-sm-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-sm-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-sm-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-sm-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-sm-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-sm-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-sm-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-sm-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-sm-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pl-sm-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-sm-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-sm-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-sm-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-sm-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-sm-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-sm-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-sm-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-sm-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-sm-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-sm-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-sm-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-sm-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-sm-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-sm-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-sm-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-sm-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-sm-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-sm-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-sm-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-sm-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-sm-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-sm-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-sm-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-sm-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-sm-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-sm-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-sm-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-sm-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-sm-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-sm-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-sm-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-sm-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-sm-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-sm-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-sm-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-sm-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-sm-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-sm-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-sm-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-sm-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-sm-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-sm-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-sm-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-sm-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-sm-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-sm-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-sm-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-sm-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .p-sm-0 {
    padding: 0rem;
    : 0rem; }
  .p-sm-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-sm-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-sm-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-sm-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-sm-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-sm-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-sm-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-sm-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-sm-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-sm-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-sm-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-sm-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-sm-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-sm-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-sm-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-sm-16 {
    padding: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 577px) {
  .mt-sm-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-sm-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-sm-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-sm-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-sm-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-sm-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-sm-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-sm-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-sm-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-sm-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-sm-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-sm-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-sm-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-sm-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-sm-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-sm-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-sm-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mr-sm-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-sm-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-sm-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-sm-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-sm-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-sm-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-sm-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-sm-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-sm-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-sm-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-sm-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-sm-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-sm-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-sm-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-sm-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-sm-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-sm-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mb-sm-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-sm-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-sm-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-sm-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-sm-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-sm-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-sm-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-sm-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-sm-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-sm-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-sm-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-sm-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-sm-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-sm-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-sm-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-sm-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-sm-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .ml-sm-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-sm-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-sm-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-sm-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-sm-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-sm-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-sm-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-sm-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-sm-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-sm-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-sm-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-sm-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-sm-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-sm-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-sm-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-sm-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-sm-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-sm-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-sm-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-sm-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-sm-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-sm-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-sm-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-sm-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-sm-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-sm-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-sm-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-sm-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-sm-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-sm-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-sm-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-sm-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-sm-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-sm-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-sm-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-sm-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-sm-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-sm-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-sm-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-sm-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-sm-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-sm-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-sm-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-sm-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-sm-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-sm-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-sm-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-sm-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-sm-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .m-sm-0 {
    margin: 0rem;
    : 0rem; }
  .m-sm-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-sm-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-sm-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-sm-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-sm-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-sm-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-sm-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-sm-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-sm-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-sm-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-sm-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-sm-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-sm-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-sm-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-sm-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-sm-16 {
    margin: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 769px) {
  .pt-md-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-md-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-md-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-md-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-md-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-md-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-md-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-md-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-md-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-md-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-md-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-md-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-md-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-md-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-md-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-md-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-md-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pr-md-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-md-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-md-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-md-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-md-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-md-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-md-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-md-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-md-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-md-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-md-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-md-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-md-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-md-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-md-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-md-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-md-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pb-md-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-md-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-md-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-md-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-md-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-md-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-md-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-md-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-md-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-md-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-md-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-md-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-md-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-md-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-md-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-md-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-md-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pl-md-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-md-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-md-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-md-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-md-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-md-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-md-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-md-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-md-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-md-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-md-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-md-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-md-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-md-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-md-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-md-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-md-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-md-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-md-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-md-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-md-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-md-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-md-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-md-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-md-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-md-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-md-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-md-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-md-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-md-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-md-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-md-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-md-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-md-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-md-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-md-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-md-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-md-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-md-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-md-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-md-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-md-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-md-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-md-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-md-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-md-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-md-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-md-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-md-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .p-md-0 {
    padding: 0rem;
    : 0rem; }
  .p-md-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-md-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-md-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-md-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-md-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-md-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-md-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-md-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-md-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-md-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-md-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-md-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-md-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-md-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-md-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-md-16 {
    padding: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 769px) {
  .mt-md-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-md-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-md-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-md-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-md-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-md-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-md-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-md-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-md-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-md-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-md-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-md-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-md-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-md-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-md-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-md-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-md-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mr-md-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-md-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-md-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-md-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-md-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-md-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-md-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-md-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-md-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-md-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-md-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-md-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-md-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-md-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-md-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-md-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-md-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mb-md-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-md-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-md-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-md-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-md-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-md-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-md-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-md-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-md-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-md-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-md-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-md-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-md-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-md-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-md-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-md-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-md-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .ml-md-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-md-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-md-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-md-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-md-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-md-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-md-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-md-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-md-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-md-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-md-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-md-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-md-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-md-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-md-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-md-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-md-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-md-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-md-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-md-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-md-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-md-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-md-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-md-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-md-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-md-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-md-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-md-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-md-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-md-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-md-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-md-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-md-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-md-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-md-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-md-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-md-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-md-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-md-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-md-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-md-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-md-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-md-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-md-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-md-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-md-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-md-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-md-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-md-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .m-md-0 {
    margin: 0rem;
    : 0rem; }
  .m-md-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-md-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-md-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-md-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-md-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-md-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-md-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-md-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-md-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-md-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-md-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-md-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-md-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-md-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-md-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-md-16 {
    margin: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1025px) {
  .pt-lg-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-lg-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-lg-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-lg-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-lg-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-lg-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-lg-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-lg-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-lg-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-lg-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-lg-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-lg-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-lg-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-lg-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-lg-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-lg-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-lg-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pr-lg-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-lg-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-lg-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-lg-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-lg-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-lg-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-lg-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-lg-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-lg-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-lg-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-lg-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-lg-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-lg-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-lg-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-lg-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-lg-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-lg-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pb-lg-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-lg-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-lg-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-lg-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-lg-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-lg-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-lg-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-lg-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-lg-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-lg-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-lg-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-lg-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-lg-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-lg-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-lg-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-lg-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-lg-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pl-lg-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-lg-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-lg-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-lg-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-lg-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-lg-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-lg-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-lg-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-lg-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-lg-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-lg-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-lg-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-lg-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-lg-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-lg-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-lg-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-lg-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-lg-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-lg-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-lg-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-lg-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-lg-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-lg-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-lg-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-lg-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-lg-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-lg-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-lg-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-lg-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-lg-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-lg-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-lg-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-lg-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-lg-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-lg-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-lg-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-lg-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-lg-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-lg-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-lg-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-lg-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-lg-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-lg-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-lg-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-lg-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-lg-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-lg-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-lg-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-lg-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .p-lg-0 {
    padding: 0rem;
    : 0rem; }
  .p-lg-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-lg-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-lg-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-lg-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-lg-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-lg-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-lg-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-lg-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-lg-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-lg-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-lg-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-lg-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-lg-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-lg-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-lg-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-lg-16 {
    padding: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1025px) {
  .mt-lg-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-lg-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-lg-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-lg-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-lg-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-lg-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-lg-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-lg-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-lg-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-lg-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-lg-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-lg-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-lg-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-lg-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-lg-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-lg-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-lg-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mr-lg-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-lg-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-lg-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-lg-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-lg-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-lg-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-lg-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-lg-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-lg-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-lg-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-lg-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-lg-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-lg-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-lg-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-lg-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-lg-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-lg-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mb-lg-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-lg-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-lg-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-lg-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-lg-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-lg-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-lg-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-lg-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-lg-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-lg-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-lg-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-lg-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-lg-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-lg-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-lg-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-lg-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-lg-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .ml-lg-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-lg-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-lg-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-lg-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-lg-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-lg-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-lg-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-lg-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-lg-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-lg-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-lg-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-lg-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-lg-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-lg-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-lg-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-lg-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-lg-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-lg-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-lg-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-lg-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-lg-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-lg-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-lg-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-lg-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-lg-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-lg-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-lg-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-lg-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-lg-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-lg-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-lg-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-lg-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-lg-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-lg-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-lg-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-lg-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-lg-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-lg-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-lg-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-lg-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-lg-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-lg-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-lg-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-lg-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-lg-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-lg-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-lg-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-lg-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-lg-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .m-lg-0 {
    margin: 0rem;
    : 0rem; }
  .m-lg-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-lg-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-lg-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-lg-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-lg-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-lg-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-lg-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-lg-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-lg-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-lg-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-lg-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-lg-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-lg-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-lg-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-lg-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-lg-16 {
    margin: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1281px) {
  .pt-xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xl-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-xl-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-xl-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-xl-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-xl-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-xl-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xl-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-xl-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-xl-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-xl-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-xl-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-xl-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xl-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-xl-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-xl-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-xl-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pr-xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xl-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-xl-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-xl-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-xl-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-xl-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-xl-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xl-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-xl-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-xl-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-xl-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-xl-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-xl-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xl-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-xl-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-xl-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-xl-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pb-xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xl-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-xl-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-xl-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-xl-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-xl-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-xl-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xl-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-xl-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-xl-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-xl-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-xl-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-xl-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xl-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-xl-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-xl-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-xl-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pl-xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xl-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-xl-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-xl-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-xl-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-xl-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-xl-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xl-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-xl-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-xl-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-xl-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-xl-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-xl-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xl-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-xl-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-xl-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-xl-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xl-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-xl-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-xl-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-xl-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-xl-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-xl-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xl-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-xl-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-xl-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-xl-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-xl-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-xl-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xl-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-xl-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-xl-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-xl-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xl-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-xl-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-xl-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-xl-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-xl-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-xl-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xl-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-xl-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-xl-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-xl-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-xl-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-xl-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xl-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-xl-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-xl-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-xl-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .p-xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xl-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-xl-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-xl-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-xl-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-xl-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-xl-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xl-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-xl-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-xl-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-xl-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-xl-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-xl-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xl-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-xl-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-xl-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-xl-16 {
    padding: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1281px) {
  .mt-xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xl-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-xl-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-xl-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-xl-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-xl-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-xl-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xl-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-xl-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-xl-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-xl-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-xl-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-xl-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xl-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-xl-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-xl-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-xl-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mr-xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xl-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-xl-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-xl-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-xl-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-xl-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-xl-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xl-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-xl-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-xl-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-xl-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-xl-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-xl-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xl-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-xl-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-xl-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-xl-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mb-xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xl-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-xl-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-xl-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-xl-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-xl-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-xl-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xl-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-xl-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-xl-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-xl-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-xl-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-xl-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xl-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-xl-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-xl-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-xl-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .ml-xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xl-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-xl-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-xl-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-xl-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-xl-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-xl-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xl-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-xl-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-xl-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-xl-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-xl-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-xl-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xl-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-xl-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-xl-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-xl-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xl-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-xl-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-xl-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-xl-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-xl-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-xl-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xl-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-xl-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-xl-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-xl-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-xl-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-xl-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xl-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-xl-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-xl-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-xl-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xl-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-xl-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-xl-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-xl-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-xl-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-xl-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xl-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-xl-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-xl-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-xl-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-xl-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-xl-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xl-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-xl-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-xl-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-xl-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .m-xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xl-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-xl-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-xl-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-xl-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-xl-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-xl-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xl-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-xl-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-xl-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-xl-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-xl-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-xl-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xl-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-xl-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-xl-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-xl-16 {
    margin: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1441px) {
  .pt-2xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-2xl-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-2xl-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-2xl-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-2xl-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-2xl-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-2xl-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-2xl-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-2xl-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-2xl-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-2xl-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-2xl-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-2xl-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-2xl-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-2xl-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-2xl-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-2xl-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pr-2xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-2xl-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-2xl-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-2xl-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-2xl-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-2xl-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-2xl-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-2xl-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-2xl-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-2xl-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-2xl-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-2xl-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-2xl-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-2xl-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-2xl-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-2xl-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-2xl-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pb-2xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-2xl-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-2xl-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-2xl-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-2xl-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-2xl-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-2xl-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-2xl-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-2xl-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-2xl-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-2xl-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-2xl-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-2xl-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-2xl-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-2xl-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-2xl-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-2xl-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pl-2xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-2xl-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-2xl-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-2xl-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-2xl-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-2xl-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-2xl-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-2xl-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-2xl-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-2xl-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-2xl-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-2xl-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-2xl-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-2xl-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-2xl-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-2xl-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-2xl-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .px-2xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-2xl-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-2xl-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-2xl-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-2xl-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-2xl-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-2xl-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-2xl-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-2xl-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-2xl-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-2xl-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-2xl-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-2xl-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-2xl-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-2xl-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-2xl-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-2xl-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .py-2xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-2xl-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-2xl-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-2xl-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-2xl-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-2xl-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-2xl-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-2xl-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-2xl-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-2xl-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-2xl-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-2xl-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-2xl-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-2xl-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-2xl-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-2xl-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-2xl-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .p-2xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-2xl-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-2xl-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-2xl-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-2xl-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-2xl-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-2xl-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-2xl-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-2xl-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-2xl-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-2xl-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-2xl-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-2xl-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-2xl-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-2xl-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-2xl-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-2xl-16 {
    padding: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1441px) {
  .mt-2xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-2xl-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-2xl-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-2xl-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-2xl-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-2xl-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-2xl-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-2xl-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-2xl-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-2xl-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-2xl-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-2xl-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-2xl-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-2xl-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-2xl-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-2xl-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-2xl-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mr-2xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-2xl-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-2xl-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-2xl-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-2xl-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-2xl-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-2xl-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-2xl-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-2xl-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-2xl-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-2xl-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-2xl-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-2xl-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-2xl-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-2xl-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-2xl-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-2xl-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mb-2xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-2xl-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-2xl-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-2xl-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-2xl-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-2xl-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-2xl-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-2xl-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-2xl-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-2xl-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-2xl-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-2xl-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-2xl-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-2xl-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-2xl-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-2xl-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-2xl-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .ml-2xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-2xl-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-2xl-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-2xl-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-2xl-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-2xl-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-2xl-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-2xl-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-2xl-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-2xl-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-2xl-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-2xl-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-2xl-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-2xl-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-2xl-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-2xl-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-2xl-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .mx-2xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-2xl-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-2xl-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-2xl-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-2xl-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-2xl-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-2xl-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-2xl-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-2xl-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-2xl-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-2xl-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-2xl-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-2xl-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-2xl-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-2xl-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-2xl-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-2xl-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .my-2xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-2xl-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-2xl-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-2xl-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-2xl-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-2xl-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-2xl-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-2xl-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-2xl-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-2xl-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-2xl-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-2xl-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-2xl-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-2xl-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-2xl-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-2xl-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-2xl-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .m-2xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-2xl-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-2xl-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-2xl-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-2xl-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-2xl-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-2xl-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-2xl-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-2xl-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-2xl-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-2xl-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-2xl-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-2xl-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-2xl-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-2xl-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-2xl-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-2xl-16 {
    margin: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1601px) {
  .pt-3xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-3xl-1 {
    padding-top: 0.20833rem;
    : 0.20833rem; }
  .pt-3xl-2 {
    padding-top: 0.41667rem;
    : 0.41667rem; }
  .pt-3xl-3 {
    padding-top: 0.625rem;
    : 0.625rem; }
  .pt-3xl-4 {
    padding-top: 0.83333rem;
    : 0.83333rem; }
  .pt-3xl-5 {
    padding-top: 1.04167rem;
    : 1.04167rem; }
  .pt-3xl-6 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-3xl-7 {
    padding-top: 1.45833rem;
    : 1.45833rem; }
  .pt-3xl-8 {
    padding-top: 1.66667rem;
    : 1.66667rem; }
  .pt-3xl-9 {
    padding-top: 1.875rem;
    : 1.875rem; }
  .pt-3xl-10 {
    padding-top: 2.08333rem;
    : 2.08333rem; }
  .pt-3xl-11 {
    padding-top: 2.29167rem;
    : 2.29167rem; }
  .pt-3xl-12 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-3xl-13 {
    padding-top: 2.70833rem;
    : 2.70833rem; }
  .pt-3xl-14 {
    padding-top: 2.91667rem;
    : 2.91667rem; }
  .pt-3xl-15 {
    padding-top: 3.125rem;
    : 3.125rem; }
  .pt-3xl-16 {
    padding-top: 3.33333rem;
    : 3.33333rem; }
  .pr-3xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-3xl-1 {
    padding-right: 0.20833rem;
    : 0.20833rem; }
  .pr-3xl-2 {
    padding-right: 0.41667rem;
    : 0.41667rem; }
  .pr-3xl-3 {
    padding-right: 0.625rem;
    : 0.625rem; }
  .pr-3xl-4 {
    padding-right: 0.83333rem;
    : 0.83333rem; }
  .pr-3xl-5 {
    padding-right: 1.04167rem;
    : 1.04167rem; }
  .pr-3xl-6 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-3xl-7 {
    padding-right: 1.45833rem;
    : 1.45833rem; }
  .pr-3xl-8 {
    padding-right: 1.66667rem;
    : 1.66667rem; }
  .pr-3xl-9 {
    padding-right: 1.875rem;
    : 1.875rem; }
  .pr-3xl-10 {
    padding-right: 2.08333rem;
    : 2.08333rem; }
  .pr-3xl-11 {
    padding-right: 2.29167rem;
    : 2.29167rem; }
  .pr-3xl-12 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-3xl-13 {
    padding-right: 2.70833rem;
    : 2.70833rem; }
  .pr-3xl-14 {
    padding-right: 2.91667rem;
    : 2.91667rem; }
  .pr-3xl-15 {
    padding-right: 3.125rem;
    : 3.125rem; }
  .pr-3xl-16 {
    padding-right: 3.33333rem;
    : 3.33333rem; }
  .pb-3xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-3xl-1 {
    padding-bottom: 0.20833rem;
    : 0.20833rem; }
  .pb-3xl-2 {
    padding-bottom: 0.41667rem;
    : 0.41667rem; }
  .pb-3xl-3 {
    padding-bottom: 0.625rem;
    : 0.625rem; }
  .pb-3xl-4 {
    padding-bottom: 0.83333rem;
    : 0.83333rem; }
  .pb-3xl-5 {
    padding-bottom: 1.04167rem;
    : 1.04167rem; }
  .pb-3xl-6 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-3xl-7 {
    padding-bottom: 1.45833rem;
    : 1.45833rem; }
  .pb-3xl-8 {
    padding-bottom: 1.66667rem;
    : 1.66667rem; }
  .pb-3xl-9 {
    padding-bottom: 1.875rem;
    : 1.875rem; }
  .pb-3xl-10 {
    padding-bottom: 2.08333rem;
    : 2.08333rem; }
  .pb-3xl-11 {
    padding-bottom: 2.29167rem;
    : 2.29167rem; }
  .pb-3xl-12 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-3xl-13 {
    padding-bottom: 2.70833rem;
    : 2.70833rem; }
  .pb-3xl-14 {
    padding-bottom: 2.91667rem;
    : 2.91667rem; }
  .pb-3xl-15 {
    padding-bottom: 3.125rem;
    : 3.125rem; }
  .pb-3xl-16 {
    padding-bottom: 3.33333rem;
    : 3.33333rem; }
  .pl-3xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-3xl-1 {
    padding-left: 0.20833rem;
    : 0.20833rem; }
  .pl-3xl-2 {
    padding-left: 0.41667rem;
    : 0.41667rem; }
  .pl-3xl-3 {
    padding-left: 0.625rem;
    : 0.625rem; }
  .pl-3xl-4 {
    padding-left: 0.83333rem;
    : 0.83333rem; }
  .pl-3xl-5 {
    padding-left: 1.04167rem;
    : 1.04167rem; }
  .pl-3xl-6 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-3xl-7 {
    padding-left: 1.45833rem;
    : 1.45833rem; }
  .pl-3xl-8 {
    padding-left: 1.66667rem;
    : 1.66667rem; }
  .pl-3xl-9 {
    padding-left: 1.875rem;
    : 1.875rem; }
  .pl-3xl-10 {
    padding-left: 2.08333rem;
    : 2.08333rem; }
  .pl-3xl-11 {
    padding-left: 2.29167rem;
    : 2.29167rem; }
  .pl-3xl-12 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-3xl-13 {
    padding-left: 2.70833rem;
    : 2.70833rem; }
  .pl-3xl-14 {
    padding-left: 2.91667rem;
    : 2.91667rem; }
  .pl-3xl-15 {
    padding-left: 3.125rem;
    : 3.125rem; }
  .pl-3xl-16 {
    padding-left: 3.33333rem;
    : 3.33333rem; }
  .px-3xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-3xl-1 {
    padding-left: 0.20833rem;
    padding-right: 0.20833rem; }
  .px-3xl-2 {
    padding-left: 0.41667rem;
    padding-right: 0.41667rem; }
  .px-3xl-3 {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .px-3xl-4 {
    padding-left: 0.83333rem;
    padding-right: 0.83333rem; }
  .px-3xl-5 {
    padding-left: 1.04167rem;
    padding-right: 1.04167rem; }
  .px-3xl-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-3xl-7 {
    padding-left: 1.45833rem;
    padding-right: 1.45833rem; }
  .px-3xl-8 {
    padding-left: 1.66667rem;
    padding-right: 1.66667rem; }
  .px-3xl-9 {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .px-3xl-10 {
    padding-left: 2.08333rem;
    padding-right: 2.08333rem; }
  .px-3xl-11 {
    padding-left: 2.29167rem;
    padding-right: 2.29167rem; }
  .px-3xl-12 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-3xl-13 {
    padding-left: 2.70833rem;
    padding-right: 2.70833rem; }
  .px-3xl-14 {
    padding-left: 2.91667rem;
    padding-right: 2.91667rem; }
  .px-3xl-15 {
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
  .px-3xl-16 {
    padding-left: 3.33333rem;
    padding-right: 3.33333rem; }
  .py-3xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-3xl-1 {
    padding-top: 0.20833rem;
    padding-bottom: 0.20833rem; }
  .py-3xl-2 {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem; }
  .py-3xl-3 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .py-3xl-4 {
    padding-top: 0.83333rem;
    padding-bottom: 0.83333rem; }
  .py-3xl-5 {
    padding-top: 1.04167rem;
    padding-bottom: 1.04167rem; }
  .py-3xl-6 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-3xl-7 {
    padding-top: 1.45833rem;
    padding-bottom: 1.45833rem; }
  .py-3xl-8 {
    padding-top: 1.66667rem;
    padding-bottom: 1.66667rem; }
  .py-3xl-9 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .py-3xl-10 {
    padding-top: 2.08333rem;
    padding-bottom: 2.08333rem; }
  .py-3xl-11 {
    padding-top: 2.29167rem;
    padding-bottom: 2.29167rem; }
  .py-3xl-12 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-3xl-13 {
    padding-top: 2.70833rem;
    padding-bottom: 2.70833rem; }
  .py-3xl-14 {
    padding-top: 2.91667rem;
    padding-bottom: 2.91667rem; }
  .py-3xl-15 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .py-3xl-16 {
    padding-top: 3.33333rem;
    padding-bottom: 3.33333rem; }
  .p-3xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-3xl-1 {
    padding: 0.20833rem;
    : 0.20833rem; }
  .p-3xl-2 {
    padding: 0.41667rem;
    : 0.41667rem; }
  .p-3xl-3 {
    padding: 0.625rem;
    : 0.625rem; }
  .p-3xl-4 {
    padding: 0.83333rem;
    : 0.83333rem; }
  .p-3xl-5 {
    padding: 1.04167rem;
    : 1.04167rem; }
  .p-3xl-6 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-3xl-7 {
    padding: 1.45833rem;
    : 1.45833rem; }
  .p-3xl-8 {
    padding: 1.66667rem;
    : 1.66667rem; }
  .p-3xl-9 {
    padding: 1.875rem;
    : 1.875rem; }
  .p-3xl-10 {
    padding: 2.08333rem;
    : 2.08333rem; }
  .p-3xl-11 {
    padding: 2.29167rem;
    : 2.29167rem; }
  .p-3xl-12 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-3xl-13 {
    padding: 2.70833rem;
    : 2.70833rem; }
  .p-3xl-14 {
    padding: 2.91667rem;
    : 2.91667rem; }
  .p-3xl-15 {
    padding: 3.125rem;
    : 3.125rem; }
  .p-3xl-16 {
    padding: 3.33333rem;
    : 3.33333rem; } }

@media (min-width: 1601px) {
  .mt-3xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-3xl-1 {
    margin-top: 0.20833rem;
    : 0.20833rem; }
  .mt-3xl-2 {
    margin-top: 0.41667rem;
    : 0.41667rem; }
  .mt-3xl-3 {
    margin-top: 0.625rem;
    : 0.625rem; }
  .mt-3xl-4 {
    margin-top: 0.83333rem;
    : 0.83333rem; }
  .mt-3xl-5 {
    margin-top: 1.04167rem;
    : 1.04167rem; }
  .mt-3xl-6 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-3xl-7 {
    margin-top: 1.45833rem;
    : 1.45833rem; }
  .mt-3xl-8 {
    margin-top: 1.66667rem;
    : 1.66667rem; }
  .mt-3xl-9 {
    margin-top: 1.875rem;
    : 1.875rem; }
  .mt-3xl-10 {
    margin-top: 2.08333rem;
    : 2.08333rem; }
  .mt-3xl-11 {
    margin-top: 2.29167rem;
    : 2.29167rem; }
  .mt-3xl-12 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-3xl-13 {
    margin-top: 2.70833rem;
    : 2.70833rem; }
  .mt-3xl-14 {
    margin-top: 2.91667rem;
    : 2.91667rem; }
  .mt-3xl-15 {
    margin-top: 3.125rem;
    : 3.125rem; }
  .mt-3xl-16 {
    margin-top: 3.33333rem;
    : 3.33333rem; }
  .mr-3xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-3xl-1 {
    margin-right: 0.20833rem;
    : 0.20833rem; }
  .mr-3xl-2 {
    margin-right: 0.41667rem;
    : 0.41667rem; }
  .mr-3xl-3 {
    margin-right: 0.625rem;
    : 0.625rem; }
  .mr-3xl-4 {
    margin-right: 0.83333rem;
    : 0.83333rem; }
  .mr-3xl-5 {
    margin-right: 1.04167rem;
    : 1.04167rem; }
  .mr-3xl-6 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-3xl-7 {
    margin-right: 1.45833rem;
    : 1.45833rem; }
  .mr-3xl-8 {
    margin-right: 1.66667rem;
    : 1.66667rem; }
  .mr-3xl-9 {
    margin-right: 1.875rem;
    : 1.875rem; }
  .mr-3xl-10 {
    margin-right: 2.08333rem;
    : 2.08333rem; }
  .mr-3xl-11 {
    margin-right: 2.29167rem;
    : 2.29167rem; }
  .mr-3xl-12 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-3xl-13 {
    margin-right: 2.70833rem;
    : 2.70833rem; }
  .mr-3xl-14 {
    margin-right: 2.91667rem;
    : 2.91667rem; }
  .mr-3xl-15 {
    margin-right: 3.125rem;
    : 3.125rem; }
  .mr-3xl-16 {
    margin-right: 3.33333rem;
    : 3.33333rem; }
  .mb-3xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-3xl-1 {
    margin-bottom: 0.20833rem;
    : 0.20833rem; }
  .mb-3xl-2 {
    margin-bottom: 0.41667rem;
    : 0.41667rem; }
  .mb-3xl-3 {
    margin-bottom: 0.625rem;
    : 0.625rem; }
  .mb-3xl-4 {
    margin-bottom: 0.83333rem;
    : 0.83333rem; }
  .mb-3xl-5 {
    margin-bottom: 1.04167rem;
    : 1.04167rem; }
  .mb-3xl-6 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-3xl-7 {
    margin-bottom: 1.45833rem;
    : 1.45833rem; }
  .mb-3xl-8 {
    margin-bottom: 1.66667rem;
    : 1.66667rem; }
  .mb-3xl-9 {
    margin-bottom: 1.875rem;
    : 1.875rem; }
  .mb-3xl-10 {
    margin-bottom: 2.08333rem;
    : 2.08333rem; }
  .mb-3xl-11 {
    margin-bottom: 2.29167rem;
    : 2.29167rem; }
  .mb-3xl-12 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-3xl-13 {
    margin-bottom: 2.70833rem;
    : 2.70833rem; }
  .mb-3xl-14 {
    margin-bottom: 2.91667rem;
    : 2.91667rem; }
  .mb-3xl-15 {
    margin-bottom: 3.125rem;
    : 3.125rem; }
  .mb-3xl-16 {
    margin-bottom: 3.33333rem;
    : 3.33333rem; }
  .ml-3xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-3xl-1 {
    margin-left: 0.20833rem;
    : 0.20833rem; }
  .ml-3xl-2 {
    margin-left: 0.41667rem;
    : 0.41667rem; }
  .ml-3xl-3 {
    margin-left: 0.625rem;
    : 0.625rem; }
  .ml-3xl-4 {
    margin-left: 0.83333rem;
    : 0.83333rem; }
  .ml-3xl-5 {
    margin-left: 1.04167rem;
    : 1.04167rem; }
  .ml-3xl-6 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-3xl-7 {
    margin-left: 1.45833rem;
    : 1.45833rem; }
  .ml-3xl-8 {
    margin-left: 1.66667rem;
    : 1.66667rem; }
  .ml-3xl-9 {
    margin-left: 1.875rem;
    : 1.875rem; }
  .ml-3xl-10 {
    margin-left: 2.08333rem;
    : 2.08333rem; }
  .ml-3xl-11 {
    margin-left: 2.29167rem;
    : 2.29167rem; }
  .ml-3xl-12 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-3xl-13 {
    margin-left: 2.70833rem;
    : 2.70833rem; }
  .ml-3xl-14 {
    margin-left: 2.91667rem;
    : 2.91667rem; }
  .ml-3xl-15 {
    margin-left: 3.125rem;
    : 3.125rem; }
  .ml-3xl-16 {
    margin-left: 3.33333rem;
    : 3.33333rem; }
  .mx-3xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-3xl-1 {
    margin-left: 0.20833rem;
    margin-right: 0.20833rem; }
  .mx-3xl-2 {
    margin-left: 0.41667rem;
    margin-right: 0.41667rem; }
  .mx-3xl-3 {
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  .mx-3xl-4 {
    margin-left: 0.83333rem;
    margin-right: 0.83333rem; }
  .mx-3xl-5 {
    margin-left: 1.04167rem;
    margin-right: 1.04167rem; }
  .mx-3xl-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-3xl-7 {
    margin-left: 1.45833rem;
    margin-right: 1.45833rem; }
  .mx-3xl-8 {
    margin-left: 1.66667rem;
    margin-right: 1.66667rem; }
  .mx-3xl-9 {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .mx-3xl-10 {
    margin-left: 2.08333rem;
    margin-right: 2.08333rem; }
  .mx-3xl-11 {
    margin-left: 2.29167rem;
    margin-right: 2.29167rem; }
  .mx-3xl-12 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-3xl-13 {
    margin-left: 2.70833rem;
    margin-right: 2.70833rem; }
  .mx-3xl-14 {
    margin-left: 2.91667rem;
    margin-right: 2.91667rem; }
  .mx-3xl-15 {
    margin-left: 3.125rem;
    margin-right: 3.125rem; }
  .mx-3xl-16 {
    margin-left: 3.33333rem;
    margin-right: 3.33333rem; }
  .my-3xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-3xl-1 {
    margin-top: 0.20833rem;
    margin-bottom: 0.20833rem; }
  .my-3xl-2 {
    margin-top: 0.41667rem;
    margin-bottom: 0.41667rem; }
  .my-3xl-3 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .my-3xl-4 {
    margin-top: 0.83333rem;
    margin-bottom: 0.83333rem; }
  .my-3xl-5 {
    margin-top: 1.04167rem;
    margin-bottom: 1.04167rem; }
  .my-3xl-6 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-3xl-7 {
    margin-top: 1.45833rem;
    margin-bottom: 1.45833rem; }
  .my-3xl-8 {
    margin-top: 1.66667rem;
    margin-bottom: 1.66667rem; }
  .my-3xl-9 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .my-3xl-10 {
    margin-top: 2.08333rem;
    margin-bottom: 2.08333rem; }
  .my-3xl-11 {
    margin-top: 2.29167rem;
    margin-bottom: 2.29167rem; }
  .my-3xl-12 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-3xl-13 {
    margin-top: 2.70833rem;
    margin-bottom: 2.70833rem; }
  .my-3xl-14 {
    margin-top: 2.91667rem;
    margin-bottom: 2.91667rem; }
  .my-3xl-15 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .my-3xl-16 {
    margin-top: 3.33333rem;
    margin-bottom: 3.33333rem; }
  .m-3xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-3xl-1 {
    margin: 0.20833rem;
    : 0.20833rem; }
  .m-3xl-2 {
    margin: 0.41667rem;
    : 0.41667rem; }
  .m-3xl-3 {
    margin: 0.625rem;
    : 0.625rem; }
  .m-3xl-4 {
    margin: 0.83333rem;
    : 0.83333rem; }
  .m-3xl-5 {
    margin: 1.04167rem;
    : 1.04167rem; }
  .m-3xl-6 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-3xl-7 {
    margin: 1.45833rem;
    : 1.45833rem; }
  .m-3xl-8 {
    margin: 1.66667rem;
    : 1.66667rem; }
  .m-3xl-9 {
    margin: 1.875rem;
    : 1.875rem; }
  .m-3xl-10 {
    margin: 2.08333rem;
    : 2.08333rem; }
  .m-3xl-11 {
    margin: 2.29167rem;
    : 2.29167rem; }
  .m-3xl-12 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-3xl-13 {
    margin: 2.70833rem;
    : 2.70833rem; }
  .m-3xl-14 {
    margin: 2.91667rem;
    : 2.91667rem; }
  .m-3xl-15 {
    margin: 3.125rem;
    : 3.125rem; }
  .m-3xl-16 {
    margin: 3.33333rem;
    : 3.33333rem; } }

.heading-1, .event-item-date .date-box .day {
  font-size: 2.5rem;
  line-height: 1.33333; }
  @media (max-width: 1440px) {
    .heading-1, .event-item-date .date-box .day {
      font-size: clamp(12px, 2.5rem, 2.70833rem); } }
  @media (max-width: 1024px) {
    .heading-1, .event-item-date .date-box .day {
      font-size: 2.29167rem; } }

.heading-2, .home-section-1 .item .number {
  font-size: 2.08333rem;
  line-height: 1.2; }
  @media (max-width: 1440px) {
    .heading-2, .home-section-1 .item .number {
      font-size: clamp(12px, 2.08333rem, 2.29167rem); } }
  @media (max-width: 1024px) {
    .heading-2, .home-section-1 .item .number {
      font-size: 1.875rem; } }

.heading-3 {
  font-size: 1.875rem;
  line-height: 1.22222; }
  @media (max-width: 1440px) {
    .heading-3 {
      font-size: clamp(12px, 1.875rem, 2.08333rem); } }
  @media (max-width: 1024px) {
    .heading-3 {
      font-size: 1.66667rem; } }

.heading-4, .project-stat-number ul li .number {
  font-size: 1.66667rem;
  line-height: 1.25; }
  @media (max-width: 1440px) {
    .heading-4, .project-stat-number ul li .number {
      font-size: clamp(12px, 1.66667rem, 1.875rem); } }
  @media (max-width: 1024px) {
    .heading-4, .project-stat-number ul li .number {
      font-size: 1.45833rem; } }

.heading-5, .gallery-item-caption, .video-item-caption, .event-item-title, .event-detail-info ul li, .news-item-big .news-item-title, .project-item-featured .project-item-title, .project-item-coming .project-item-title, .article-content h2 {
  font-size: 1.25rem;
  line-height: 1.33333; }
  @media (max-width: 1440px) {
    .heading-5, .gallery-item-caption, .video-item-caption, .event-item-title, .event-detail-info ul li, .news-item-big .news-item-title, .project-item-featured .project-item-title, .project-item-coming .project-item-title, .article-content h2 {
      font-size: clamp(12px, 1.25rem, 1.45833rem); } }

.body-1, .contact-info .logo-contact p, .history-content-slider .history-item .year, .gallery-slider-thumbnail .img figcaption, .project-item-small .project-item-title {
  font-size: 1.04167rem;
  line-height: 1.4; }
  @media (max-width: 1440px) {
    .body-1, .contact-info .logo-contact p, .history-content-slider .history-item .year, .gallery-slider-thumbnail .img figcaption, .project-item-small .project-item-title {
      font-size: clamp(12px, 1.04167rem, 1.25rem); } }

.body-2 {
  font-size: 0.9375rem;
  line-height: 1.33333; }
  @media (max-width: 1440px) {
    .body-2 {
      font-size: clamp(12px, 0.9375rem, 1.14583rem); } }

.body-3, .footer-bottom h3, .footer-bottom .footer-link ul li a, .menu ul li a, .career-list table, .contact-info, .subnav ul li a, .event-item, .home-section-1 .item .title, .news-item, .detail-meta, .project-stat-number ul li .title, .project-item-featured .project-item-brief, .project-item-coming .project-item-brief {
  font-size: 0.83333rem;
  line-height: 1.5; }
  @media (max-width: 1440px) {
    .body-3, .footer-bottom h3, .footer-bottom .footer-link ul li a, .menu ul li a, .career-list table, .contact-info, .subnav ul li a, .event-item, .home-section-1 .item .title, .news-item, .detail-meta, .project-stat-number ul li .title, .project-item-featured .project-item-brief, .project-item-coming .project-item-brief {
      font-size: clamp(12px, 0.83333rem, 1.04167rem); } }

.body-4, .footer-top h3, .project-item-small .project-item-brief {
  font-size: 0.72917rem;
  line-height: 1.42857; }
  @media (max-width: 1440px) {
    .body-4, .footer-top h3, .project-item-small .project-item-brief {
      font-size: clamp(12px, 0.72917rem, 0.9375rem); } }

.body-5, .news-item-horizontal .news-item-meta {
  font-size: 0.625rem;
  line-height: 1.33333; }
  @media (max-width: 1440px) {
    .body-5, .news-item-horizontal .news-item-meta {
      font-size: clamp(12px, 0.625rem, 0.83333rem); } }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-center {
  justify-content: center;
  align-items: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-baseline {
  align-items: baseline; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.hidden, .d-none, [hidden] {
  display: none; }

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 1025px) {
  .hidden-desktop {
    display: none; } }

.text-transform-none {
  text-transform: none; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold, .text-bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: sticky; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.rounded {
  border-radius: 50%; }

.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

.leading-tight {
  line-height: 1.25; }

.leading-snug {
  line-height: 1.333333; }

.leading-normal {
  line-height: 1.5; }

.leading-relaxed {
  line-height: 1.625; }

.leading-loose {
  line-height: 2; }

.overflow-hidden {
  overflow: hidden; }

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
