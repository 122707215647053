.home-section-1
	position: relative
	.item
		+layout-col(12px)
		padding: r(28px) r(32px)
		border-radius: r(16px)
		box-shadow: 4px 6px 24px 0px rgba(0, 0, 0, 0.04)
		+mq-max(sm)
			padding: r(24px)
		.icon
			width: r(80px)
			figure
				margin: 0 auto
				+res-ratio(1,1)
				img
					object-fit: contain
		.number
			@extend .heading-2
			+text(primary-600)
		.title
			@extend .body-3
	.text-bg
		position: absolute
		top: 0
		width: 100%
		left: 0
	.content-container
		z-index: 2