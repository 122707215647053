.about-section-4
	position: relative
	&::before
		+pseudo
		bottom: 0
		right: 0
		width: r(572px)
		height: r(693px)
		background-image: url(../img/tree-bg-2.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: center center
	.heading-1
		-webkit-background-clip: text
		-webkit-text-fill-color: transparent
		background-image: linear-gradient(180deg, #B2151B 0%, #770E12 100%)