.about-section-1
	background-size: cover
	background-position: bottom center
	background-repeat: no-repeat
	+mq-max(md)
		padding-bottom: r(140px) !important
	+mq-min(md)
		min-height: r(876px)
	.content
		overflow: auto
		padding-right: r(28px)
		+scrollbars(r(4px),color(primary-600),color(gray-100))
		max-height: r(400px)
		+mq-min(lg)
			max-height: r(516px)
			text-align: center

