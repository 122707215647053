.global-header
	padding: r(4px) 0
	+bg-gra(linear-2)
	+text(white)
	position: relative

.menu-wrapper
	+mq-max(lg)
		position: absolute
		top: 100%
		left: 0
		width: 100%
		+bg-gra(linear-2)
		border-top: 1px solid #fff
		display: none

.logo
	+mq-max(sm)
		width: r(72px)

.menu
	ul
		display: flex
		gap: r(48px)
		+mq-max(lg)
			flex-direction: column
			gap: r(20px)
			padding: r(32px)
		li
			a
				display: flex
				align-items: center
				justify-content: center
				padding-bottom: r(4px)
				@extend .body-3
				+trans-all
				font-weight: 700
				text-transform: uppercase
				+mq-max(lg)
					display: inline-flex

			&.active
				a
					+text(primary-200)
					border-bottom: 2px solid color(primary-200)
			+on-hover
				a
					+text(primary-200)

.search-popup
	position: fixed
	z-index: 1001
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	pointer-events: none
	display: none
	.search-container
		width: 100%
		height: 100%
		+flex-center
		display: flex
		pointer-events: none
	.searchbox
		pointer-events: all
		position: relative
		input
			width: 70vw
			height: r(48px)
			padding: 0 r(72px) 0 r(20px)
			+fz(20px)
		button
			position: absolute
			top: 0
			right: 0
			height: 100%
			width: r(48px)
			background: transparent
			border: 0
			+fz(20px)

.header-divider
	width: 1px
	height: r(18px)
	+bg(white)
	opacity: 0.3

.language
	.current
		display: flex
		gap: r(6px)
		align-items: center
		span
			font-size: r(14px)
		.fa-chevron-down
			opacity: 0.3
	.dropdown-content
		min-width: auto
		ul
			padding: r(8px)
			display: flex
			flex-direction: column
			gap: r(8px)
			+text(gray-700)

.menu-toggle
	+box(44px)
	display: flex
	+flex-center
	+bg(white)
	+text(primary-700)
	border-radius: r(8px)

.backdrop
	+fill
	position: fixed
	opacity: 0.5
	+bg(gray-900)
	z-index: 1000
	display: none
