.gallery-item
	&-img
		display: block
		position: relative
		overflow: hidden
		figure
			+res-ratio(640,360)
	&-caption
		position: absolute
		padding: 0 r(20px)
		bottom: r(32px)
		text-align: center
		width: 100%
		left: 0
		+text(white)
		@extend .heading-5
		font-weight: 700
		text-transform: uppercase
		+mq-min(lg)
			transform: translateY(50%)
			opacity: 0
			+trans-all
	+on-hover
		.gallery-item-caption
			transform: none
			opacity: 1