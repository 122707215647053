.detail-meta
	display: flex
	align-items: center
	gap: r(12px)
	+text(gray-300)
	@extend .body-3
	padding-bottom: r(20px)
	border-bottom: 1px solid color(gray-200)
	span
		display: inline-block
		width: 4px
		height: 4px
		+bg(gray-400)
