.home-bg-2
	background-size: cover
	background-position: bottom center
	background-repeat: no-repeat

.gallery-slider-images
	.img
		figure
			+res-ratio(2,1)

.gallery-slider-thumbnail
	.img
		+bg(white)
		+trans-all
		figure
			+res-ratio(256,180)
		figcaption
			padding: r(16px)
			text-align: center
			@extend .body-1
			font-weight: 700
			text-transform: uppercase
			border-bottom: 1px solid color(primary-600)
			+text(primary-600)
			+trans-all
	.swiper-slide-thumb-active
		.img
			+bg(primary-600)
			figcaption
				+text(white)