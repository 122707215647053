.breadcrumb-wrapper
	+remove(lg, 'max')
	background: linear-gradient(152deg, #FDF3EC 0%, #FAF4C4 100%)
	+text(gray-500)
	.breadcrumb
		padding: r(8px) 0
		display: flex
		align-items: center
		li
			a
				+fz-lh(14px,20px)
				display: flex
				align-items: center
				font-weight: 500
			+ li
				padding-left: r(12px)
				display: flex
				align-items: center
				&::before
					content: "/"
					margin-right: r(12px)
					font-size: r(14px)
			+first(1)
				a
					font-size: 0
					&:before
						content: '\f015'
						font-family: 'Font Awesome 6 Pro'
						+fz-lh(14px,20px)
						font-weight: 400