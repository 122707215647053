.career-policy-list
	.item
		+layout-col(32px)
		align-items: center
		.icon
			figure
				width: r(70px)
				margin: 0 auto
				+res-ratio(1,1)
				img
					object-fit: contain
	.text-bg
		position: absolute
		bottom: 0
		right: 0
		opacity: 0.5

.career-list
	+mq-max(lg)
		overflow: auto
		table
			width: 200%
	table
		counter-reset: section
		width: 100%
		+bg(white)
		@extend .body-3
		text-align: left
		th,td
			padding: r(12px) r(20px)
			border-bottom: 1px solid color(gray-100)
		tbody
			tr
				.num
					&::before
						counter-increment: section
						content: counters(section, ".", decimal-leading-zero)
		th
			font-weight: 700
			+text(primary-600)